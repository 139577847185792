import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-donwload-button',
  templateUrl: './donwload-button.component.html',
  styleUrls: ['./donwload-button.component.scss'],
})
export class DonwloadButtonComponent implements OnInit {
  @Input() iconUrl: string = '';
  @Input() title: string = '';
  @Input() color: string = '';
  @Input() iconAction: string = '';
    @Input() download: boolean =false;



  public colorClass: string = 'bg-accent1';

  constructor() {}

  ngOnInit(): void {
    this.colorClass = 'bg-' + this.color;
  }
}
