import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MediaObject } from 'src/app/models/mediaObject';
import { ProgressObject } from 'src/app/models/progressObject';
import { ProgressType } from 'src/app/models/enums/progressType';
import { FillerObject } from 'src/app/models/fillerObject';
import { TestimonialObject } from 'src/app/models/testimonialObject';
@Injectable({
  providedIn: 'root',
})
export class MediaService {
  /*
   * mediaHome and mediaTestimoniaalsPage NEEDS the SAME list length!
   */
  //video gif frame
  private mediaHome: MediaObject[] = [
    new MediaObject(
      0,
      'collection home',
      'home media',
      'media 0',
      'media 0 home',
      'click me',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7668ef23-3bb0-4653-9dcf-2e3451c1ed00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/60471657-5bc5-478f-c61a-2944b2baae00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7668ef23-3bb0-4653-9dcf-2e3451c1ed00/public',
      '',
      ''
    ),
    new MediaObject(
      1,
      'collection home',
      'home media',
      'media 1',
      'media 1 home',
      'click me',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/842bc649-e9e5-4d13-ed47-b1cf936cfa00/public4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/34a26438-ea73-4114-e670-237b126ce200/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/842bc649-e9e5-4d13-ed47-b1cf936cfa00/public',

      '',
      ''
    ),
    new MediaObject(
      2,
      'collection home',
      'home media',
      'media 2',
      'media 2 home',
      'click me',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/a3a9928f-6ab7-46b2-35e1-350b7beee400/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/68dbf4dd-fcf4-42be-35a5-cae796c18600/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/a3a9928f-6ab7-46b2-35e1-350b7beee400/public',

      '',
      ''
    ),
    new MediaObject(
      3,
      'collection home',
      'home media',
      'media 3',
      'media 3 home',
      'click me',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/221a3465-0bc0-4395-c310-3896d9d60800/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/a9d7d8db-2315-4850-34e9-e50ae1d4b500/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/221a3465-0bc0-4395-c310-3896d9d60800/public',

      '',
      ''
    ),
    new MediaObject(
      4,
      'collection home',
      'home media',
      'media 4',
      'media 4 home',
      'click me',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/075a3411-0693-4335-9706-a9cd44e6fd00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/4c0773e1-749f-4a57-c57b-31325e7c3600/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/075a3411-0693-4335-9706-a9cd44e6fd00/public',
      '',
      ''
    ),
    new MediaObject(
      5,
      'collection home',
      'home media',
      'media 5',
      'media 5 home',
      'click me',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7d1ef3f3-5a8c-4815-c2d5-6200613c5f00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/581e7240-f2bc-427c-ab34-2887050df500/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7d1ef3f3-5a8c-4815-c2d5-6200613c5f00/public',
      '',
      ''
    ),
  ];

  private mediaVideoPage: MediaObject[] = [
    new MediaObject(
      0,
      'collection video page',
      'A DREAM SHARED IS A DREAM COME TRUE',
      'A DREAM SHARED IS A DREAM COME TRUE',
      'WeDream takes dreams out of your head and lets them loose in the real world. Everything starts with a dream: an idea, a little spark, good things, funny things, scary things – things the world needs to hear. \n Join us and share your dreams with the world!',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/make%20your%20dreams%20happen.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/ed4dae15-51d6-4794-6828-40b4048e2800/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/ed4dae15-51d6-4794-6828-40b4048e2800/public',
      '',
      ''
    ),
    new MediaObject(
      1,
      'collection video page',
      'WHAT’S IN YOUR HEAD?',
      'WHAT’S IN YOUR HEAD?',
      'WeDream is a social platform where you can post your dreams and share them with the world. That’s when the magic begins. As you swipe through the app, you’ll discover dreams from people all over the world, shared anonymously. Seen a dream that appeals to you? Then swipe right to like it. If the dreamer likes your dream in return, the app will connect you. What’s next is up to you. Realize your dreams together: because together is always better!',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/connecting%20dreams.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/c829ab75-bfef-4f12-9520-3a0a00479600/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/c829ab75-bfef-4f12-9520-3a0a00479600/public',
      '',
      ''
    ),
    new MediaObject(
      2,
      'collection video page',
      'MAKING THE DREAM WORK',
      'MAKING THE DREAM WORK',
      'The ultimate connection? That’s one that brings you closer to your life goals. WeDream connects you anonymously with people who share your dreams. You’ll meet cool people, build a community, find ways to help each other out, or just share some laughs. Small dreams, big ones and everything in between: it’s all good on WeDream.',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/connecting%20people.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/b44fbdb0-143a-4b82-16ca-47125d3e6a00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/b44fbdb0-143a-4b82-16ca-47125d3e6a00/public',
      '',
      ''
    ),
    new MediaObject(
      3,
      'collection video page',
      'SO WHAT DOES IT DO?',
      'SO WHAT DOES IT DO?',
      'WeDream is like a dating app for your mind. Start by sharing some information about yourself, and then you’re ready to get to the real stuff: writing down your dreams. Take a look at your feed and filter to find stuff that speaks to you. And swipe, swipe, swipe! Are you dreaming of a tropical vacation? So are we! There’s always someone out there who sees the world the same way you do. Let’s gooo!',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/everybody%20dreams.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/2a10db90-4397-40a3-ad5f-bd4bae885300/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/2a10db90-4397-40a3-ad5f-bd4bae885300/public',
      '',
      ''
    ),
    new MediaObject(
      4,
      'collection video page',
      'BE A TRAILBLAZER',
      'BE A TRAILBLAZER',
      `Ready? Yes? Yesss! Let's do this. WeDream is currently in a pilot phase. And you can fly with us. Sign up now and join a community of early risers. Because dreams aren’t meant to stay in your head. Get up, unleash them and see what comes your way!`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/The%20sky%20is%20the%20limit.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/5cb99bb3-bdaf-4f16-564a-f6c1839d5d00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/5cb99bb3-bdaf-4f16-564a-f6c1839d5d00/public',
      '',
      ''
    ),
    new MediaObject(
      5,
      'collection video page',
      'DREAM THE FUTURE',
      'DREAM THE FUTURE',
      `Where will this dream of ours go next? It’s all up to you. We’re continuing to build new features and to improve your experience. Dream with us and let us know what you’re envisioning for the future. This is only the beginning!`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/take%20the%20leap.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/05e03e39-abcf-4dc3-d04d-747ce8779d00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/05e03e39-abcf-4dc3-d04d-747ce8779d00/public',
      '',
      ''
    ),
  ];

  /*
   * !different id's for each object
   */
  private mediaTestimonialPage: MediaObject[] = [
    new MediaObject(
      0,
      '',
      'test',
      'test image',
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentiumtenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      1,
      '',
      'test',
      'test image',
      '',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      2,
      '',
      'test',
      'test image',
      '',
      '',
      'https://media.istockphoto.com/videos/33rd-happy-birthday-text-greeting-and-wishes-card-made-from-glitter-video-id1012357284',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      3,
      '',
      'test',
      'test image',
      '',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      4,
      '',
      'test',
      'test image',
      '',
      '',
      '',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      5,
      '',
      'test',
      'test image',
      '',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
  ];

  //mobile and ipad version content!!
  private mediaTestimonialPageSmall: MediaObject[] = [
    new MediaObject(
      0,
      '',
      'test',
      'test image',
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentiumtenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      1,
      '',
      'test',
      'test image',
      '',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      2,
      '',
      'test',
      'test image',
      '',
      '',
      'https://media.istockphoto.com/videos/33rd-happy-birthday-text-greeting-and-wishes-card-made-from-glitter-video-id1012357284',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      3,
      '',
      'test',
      'test image',
      '',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      4,
      '',
      'test',
      'test image',
      '',
      '',
      '',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
    new MediaObject(
      5,
      '',
      'test',
      'test image',
      '',
      '',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://www.fillmurray.com/640/360',
      'https://www.fillmurray.com/640/360',
      '',
      ''
    ),
  ];

  /*progress objects for each media - phases*
   * mediaId must be id of existing object!
   * 1 CIRCLE type !
   */
  private progressPhasesPage: ProgressObject[] = [
    /*1*/
    new ProgressObject(0, 1, 85, '', '', ProgressType.CIRCLE),
    new ProgressObject(
      0,
      1,
      95,
      'BUILDING KNOWLEDGE',
      'We’re still at it!',
      ProgressType.BAR
    ),
    new ProgressObject(
      0,
      1,
      75,
      'DREAMING OF TOMORROW',
      'Never done',
      ProgressType.BAR
    ),

    /*2*/
    new ProgressObject(1, 2, 99, '', '', ProgressType.CIRCLE),
    new ProgressObject(
      1,
      2,
      100,
      'CODE, CODE, CODE',
      'All done!',
      ProgressType.BAR
    ),
    new ProgressObject(
      1,
      2,
      98,
      'DETAILS',
      'Forever tweaking',
      ProgressType.BAR
    ),

    /*3*/
    new ProgressObject(1, 3, 75, '', '', ProgressType.CIRCLE),
    new ProgressObject(
      1,
      3,
      100,
      'FULLY FUNCTIONAL APP',
      'It’s here!',
      ProgressType.BAR
    ),
    new ProgressObject(
      1,
      3,
      50,
      'USER BASE',
      'That’s you – come join us!',
      ProgressType.BAR
    ),

    /*4*/
    new ProgressObject(1, 4, 30, '', '', ProgressType.CIRCLE),
    new ProgressObject(
      1,
      4,
      25,
      'IMPLEMENTING IMPROVEMENTS',
      'Closer every day',
      ProgressType.BAR
    ),
    new ProgressObject(
      1,
      4,
      35,
      'THINKING OF COOL NEW STUFF',
      'Day and night',
      ProgressType.BAR
    ),

    /*5*/
    new ProgressObject(1, 5, 30, '', '', ProgressType.CIRCLE),
    new ProgressObject(
      1,
      5,
      20,
      'GROWING OUR USER BASE',
      'The bigger, the better!',
      ProgressType.BAR
    ),
    new ProgressObject(
      1,
      5,
      40,
      'CREATING FUN REWARDS',
      'Just because we like to surprise you',
      ProgressType.BAR
    ),

    /*6*/
    new ProgressObject(1, 6, 10, '', '', ProgressType.CIRCLE),
    new ProgressObject(1, 6, 15, 'DREAMING', 'Constantly', ProgressType.BAR),
    new ProgressObject(1, 6, 5, 'MAKING IT HAPPEN', 'Soon!', ProgressType.BAR),
  ];

  /*
   * !different id's for each object !
   */
  private mediaPhasesPage: MediaObject[] = [
    new MediaObject(
      1,
      'collection phases',
      'WINTER 2021 AND (LONG) BEFORE: RESEARCH',
      'WINTER 2021',
      `WeDream is built on months of research into the goals and dreams of Gen Z. Not to mention, of course, that we are Gen Z and we were not finding the social platform we wanted out there.
     Sure, there are plenty of dating apps and social networking sites. But something is missing that’s hard to find: the feeling of working towards a common goal with people who share your views and values.
     Why be cynical about our dreams when we can actually make them come true? WeDream was born.
     `,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/realtime%20analysis.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/1df2e03c-3d52-4781-03ed-580c6980f000/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/1df2e03c-3d52-4781-03ed-580c6980f000/public',
      '',
      ''
    ),
    new MediaObject(
      2,
      'collection phases',
      'SPRING 2022: DEVELOPMENT',
      'SPRING 2022',
      `Building an app takes work. A lot of it. Every line of code and every piece of design you see was made by us. Why? Because we’re detail-oriented perfectionists. And we want WeDream to be nothing short of perfect.`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/dreaming%20together.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/324099db-5efd-47c5-12de-6c168663aa00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/324099db-5efd-47c5-12de-6c168663aa00/public',
      '',
      ''
    ),
    new MediaObject(
      3,
      'collection phases',
      'JULY 2022: BETA',
      'JULY 2022',
      `It’s here! WeDream is fully functional and finally available for download from the App Store and Google Play. An open beta is a scary beast, because we know that you’ll find errors during use. Trust us: we’re working on it as we speak.`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/finding%20community.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/827330ea-71be-4095-ab18-84835ab9e900/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/827330ea-71be-4095-ab18-84835ab9e900/public',
      '',
      ''
    ),
    new MediaObject(
      4,
      'collection phases',
      'SEPTEMBER 2022: LAUNCH',
      'SEPTEMBER 2022',
      `In just a few months’ time, we’ll have ironed out every little crease in this beautiful app of ours. Time for fireworks and champagne! Expect a festive announcement and some fun shenanigans, because we’re not letting this go by unnoticed.`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/into%20the%20world.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/ee6897a5-8e78-4622-d6ab-05d7eda05b00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/ee6897a5-8e78-4622-d6ab-05d7eda05b00/public',
      '',
      ''
    ),
    new MediaObject(
      5,
      'collection phases',
      'PLANS FOR THE REST OF THIS YEAR',
      'THIS YEAR',
      `As WeDream reaches our growth goals, we want to reward you for being an early riser and an enthusiastic dreamer. Look forward to fun easter eggs and rewards as we continue to grow this wonderful community!`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/connection%20is%20fearless.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/b668450e-7796-497a-e6f3-e58e05578f00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/b668450e-7796-497a-e6f3-e58e05578f00/public',
      '',
      ''
    ),
    new MediaObject(
      6,
      'collection phases',
      'PLANS FOR THE FUTURE',
      'FUTURE',
      `And after 2022? We’ll be catching up on some sleep, maybe. But most of all, we’ll be thinking of more ways to do what we love: build connections and make dreams come true.`,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/no%20limits.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7fd3509d-3300-4455-e5ad-e0bebfe01a00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7fd3509d-3300-4455-e5ad-e0bebfe01a00/public',
      '',
      ''
    ),
  ];

  /*media for vision page
   * MAX 4 items!
   */
  private mediaVisionPage: MediaObject[] = [
    new MediaObject(
      0,
      'collection phase',
      'WeTeam',
      'WeTeam',
      `WeDream is created by I For You, a young team full of positive vibes. We are software engineers, styling wizards, community experts and security nerds. Our motto? “If it sounds scary, it must be worth doing.” Our ultimate goal is to build a community that makes dreams come true, whether they're mini or maxi. I For You doesn’t stop until it’s perfect. WeDream is our dream. We’re so happy you are here.`,
      'click me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/f72b0a45-a588-4a99-6e07-65facb93a900/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/875ccb36-3628-4ed1-d492-1ebad6fe7c00/public',
      '',
      ''
    ),
    new MediaObject(
      1,
      'collection phase',
      'Dreams need power too',
      'Dreams need power too',
      `When you’re dreaming, we don’t want anything to disrupt you. So we’ve built a high-powered app that just works. No weird glitches or long loading times. Just the functionality you expect, when you expect it. 
      WeDream is built on fast technology and according to best practices. While you are using the app, we are hard at work to add new features and power up this dream machine.
      `,
      'click me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/e4625692-78c2-4712-1d69-26b84080cf00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/875ccb36-3628-4ed1-d492-1ebad6fe7c00/public',
      '',
      ''
    ),
    new MediaObject(
      2,
      'collection phase',
      'We’re hard at work for you',
      'We’re hard at work for you',
      `WeDream is built on hours (or actually, days, weeks and months) of research and development. What you see today is an open beta version, so you might see little errors here and there. We’re working on them as we speak.
      Want to make sure we’ve noticed what you’re seeing? Let us know! We are happy and grateful to read your feedback.`,
      'click me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/61254240-af60-4713-514c-ef392419a500/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/875ccb36-3628-4ed1-d492-1ebad6fe7c00/public',
      '',
      ''
    ),
    new MediaObject(
      3,
      'collection phase',
      'Anonymous and safe',
      'Anonymous and safe',
      `Writing down your dreams for yourself, the future and the world and sharing them on an app? That takes guts! There are two things you need to know.
      One: you remain anonymous on WeDream until you make a match. Only dreamers you’ve matched with can view your profile and chat with you. Don’t feel comfortable about a dream or a match? Report it to us! Our team is always on the lookout to keep the app safe for everyone.
      And two: privacy is our priority. We eat encryption for breakfast and do everything we can to keep your data safe. All so you can focus on the heart of the matter: making your dreams come true.`,
      'click me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/3be70848-4d97-42da-e212-05a960ec5d00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/875ccb36-3628-4ed1-d492-1ebad6fe7c00/public',
      '',
      ''
    ),
  ];

  /*media for social page
   * MAX 4 items!
   */
  private mediaSocialPage: MediaObject[] = [
    new MediaObject(
      0,
      'social',
      'FACEBOOK',
      'TITTEL AAN DE ONDERKANT',
      'Dreams connect people. We connect dreams.',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7cc875d9-b607-400c-617a-21842cdeaf00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/7cc875d9-b607-400c-617a-21842cdeaf00/public',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/facebookLogo.svg',
      'https://www.facebook.com/wedream.iforyou'
    ),
    new MediaObject(
      1,
      'social',
      'INSTAGRAM',
      'TITTEL AAN DE ONDERKANT',
      'Dreams connect people. We connect dreams.',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/450c23b3-e1ab-47d8-3fc7-a9adc935bf00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/450c23b3-e1ab-47d8-3fc7-a9adc935bf00/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/0ddf03cf-31a8-428a-6be9-d4463b38c000/public',
      'https://www.instagram.com/wedream.iforyou/'
    ),
    new MediaObject(
      2,
      'social',
      'TIKTOK',
      'TITTEL AAN DE ONDERKANT',
      'Dreams connect people. We connect dreams.',
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/099d5c51-0462-445a-4368-38de17aa7600/public',
      'https://imagedelivery.net/UhBn2pLKJ-TPiiKi3e7mIQ/099d5c51-0462-445a-4368-38de17aa7600/public',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/tiktok-logo.svg',
      'https://www.tiktok.com/@wedream.iforyou'
    ),
  ];

  /*filler objects
   * 1. filler after home, before testimonials
   * 2. filler after vision before phases
   */
  private fillers: FillerObject[] = [
    new FillerObject(
      0,
      'Dreams',
      'connect',
      'people',
      'we',
      'connect',
      'dreams'
    ),
    new FillerObject(0, 'for', 'young', 'people', 'by', 'young', 'people'),
  ];

  private videoHome: MediaObject = new MediaObject(
    0,
    'collection video home',
    'CONNECTING DREAMS',
    'WE CONNECT DREAMS',
    `Welcome to WeDream. Join the revolution of dreaming.
    WeDream is a place to put your dreams and meet people who share them. Don’t keep your dreams in your head. Use them to build real connections.`,
    'watch me',
    'https://iforyou.ams3.cdn.digitaloceanspaces.com/promo.mp4',
    'https://iforyou.ams3.cdn.digitaloceanspaces.com/videohome.webp',
    'https://iforyou.ams3.cdn.digitaloceanspaces.com/videohome.webp',
    '',
    ''
  );

  private videoTester: MediaObject[] = [
    new MediaObject(
      0,
      'collection teser',
      'BE A TRAILBLAZER',
      'BE A TRAILBLAZER',
      `Ready? Yes? Yesss! Let's do this. WeDream is currently in a pilot phase. And you can fly with us. Sign up now and join a community of early risers. Because dreams aren’t meant to stay in your head. Get up, unleash them and see what comes your way!    `,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/snowboard.mp4',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/snowboardv2.webp',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/snowboardv2.webp',
      '',
      ''
    ),
    new MediaObject(
      1,
      'collection video home',
      'Come on in, the water’s fine',
      'Come on in, the water’s fine',
      `Are you always up for something new and exciting? Or just too curious to restrain yourself? We’re ready for you! WeDream is finally here and available for download. We’re proud of the work and excited about what the app can do for you. 
    Download WeDream now, build connections and discover how dreams can come to life. Our dream is in your hands!
    `,
      'watch me',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/snowboard.mp4',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/snowboardv2.webp',
      'https://iforyou.ams3.cdn.digitaloceanspaces.com/snowboardv2.webp',
      '',
      ''
    ),
  ];

  public getMediaHome(): MediaObject[] {
    return this.mediaHome;
  }

  public getVideoHome(): MediaObject {
    return this.videoHome;
  }

  public getmediaVideoPage(): MediaObject[] {
    return this.mediaVideoPage;
  }
  public getMediaTestimonialPage(): MediaObject[] {
    return this.mediaTestimonialPage;
  }

  public getMediaTestimonialPageSmall(): MediaObject[] {
    return this.mediaTestimonialPageSmall;
  }
  public getMediaPhasesPage(): MediaObject[] {
    return this.mediaPhasesPage;
  }
  public getProgressPhasesPage(): ProgressObject[] {
    return this.progressPhasesPage;
  }
  public getMediaVisionPage(): MediaObject[] {
    return this.mediaVisionPage;
  }

  public getMediaTesterPage() {
    return this.videoTester;
  }

  public getMediaSocialPage(): MediaObject[] {
    return this.mediaSocialPage;
  }

  public getHomeFiller(): FillerObject {
    return this.fillers[0];
  }

  public getTesterFiller(): FillerObject {
    return this.fillers[1];
  }
}
