import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TesterRoutingModule } from './tester-routing.module';
import { TesterComponent } from './tester.component';
import { TesterFormComponent } from './tester-form/tester-form.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    TesterComponent,
    TesterFormComponent
  ],
  imports: [
    CommonModule,
    TesterRoutingModule,
    SharedModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    TesterComponent,
    TesterFormComponent

  ]
})
export class TesterModule { }
