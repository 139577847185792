import { Component, Input, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-tester-small',
  templateUrl: './tester-small.component.html',
  styleUrls: ['./tester-small.component.scss'],
})
export class TesterSmallComponent implements OnInit {
  @Input() media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );

  constructor(private mediaService: MediaService) {}

  ngOnInit(): void {}
}
