import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { NavigationService } from 'src/app/core/services/navigationService';

@Component({
  selector: 'app-sign-up-button',
  templateUrl: './sign-up-button.component.html',
  styleUrls: ['./sign-up-button.component.scss']
})
export class SignUpButtonComponent implements OnInit {
  public active: boolean = false;
  constructor(private nav: NavigationService) {
    this.nav.getActiveNavItem().subscribe((index) => {
      this.active = (index == 100);
    })
  }

  public navigateTester(): void {
    this.nav.setEnableScrollEvent(false);
  }
  ngOnInit(): void {

  }

}
