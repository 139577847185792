<div>
  <!-- titles -->
  <div class="d-flex flex-wrap align-items-center">
    <!-- 
  site small   -->
    <!-- progress bars -->
    <div
      *ngFor="let item of progress; let i = index"
      style="width: fit-conten"
      class="site-small"
    >
      <!-- circle  -->
      <ng-template [ngIf]="item.type == progressType.CIRCLE" [ngIfElse]="bar">
        <div class="d-flex flex-wrap justify-content-center circle-cont">
          <div class="score score-accent1">
            <svg height="80px" width="80px">
              <circle
                cx="50%"
                cy="50%"
                r="32px"
                style="{{ calculateScore(item.progress) }}"
              />
            </svg>
            <div class="score-text d-flex flex-wrap justify-content-center">
              {{ item.progress }} <span>%</span>
            </div>
          </div>
          <div class="fw-700 col-60 text-center mb-2 complete-circle">
            Completed
          </div>
        </div>
      </ng-template>

      <ng-template #bar>
        <div class="mb-3">
          <div class="progressbar-cont">
            <div class="rotate-container w-100">
              <h2 class="w-100 phase-title">{{ item.title }}</h2>
              <div
                class="progressbar position-relative"
                style="margin-top: 10px !important"
              >
                <ng-template [ngIf]="item.progress == 100">
                  <div class="complete text-upper fg-color2">Completed</div>
                </ng-template>
                <div
                  [ngClass]="{ 'bgi-accent2': item.progress < 100 }"
                  class="progress bg-accent1"
                  [ngStyle]="{ width: item.progress + '%' }"
                  style="height: 12px !important"
                >
                  <div class="rad-pill bg-color3"></div>
                </div>
              </div>
              <div
                *ngIf="item.progress < 100"
                class="sub-title fg-color1 site-medium"
              >
                {{ item.subTitle }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- medium screen  -->
    <!-- progress bars -->
    <div *ngFor="let item of progress; let i = index" class="site-medium w-100">
      <!-- circle  -->
      <ng-template [ngIf]="item.type == progressType.CIRCLE" [ngIfElse]="bar">
        <div class="d-flex flex-wrap align-items-center mb-1">
          <div class="d-flex flex-wrap justify-content-center circle-cont">
            <div class="score score-accent1">
              <svg height="80px" width="80px">
                <circle
                  cx="50%"
                  cy="50%"
                  r="32px"
                  style="{{ calculateScore(item.progress) }}"
                />
              </svg>
              <div class="score-text d-flex flex-wrap justify-content-center">
                {{ item.progress }} <span>%</span>
              </div>
            </div>
          </div>
          <div class="fw-700 mb-2 complete-circle">Completed</div>
        </div>
      </ng-template>

      <ng-template #bar>
        <div class="mb-1">
          <div class="w-100">
            <h2 class="w-100">{{ item.title }}</h2>
            <div class="progressbar col-60" style="margin-top: 5px !important">
              <div
                [ngClass]="{ 'bgi-accent2': item.progress < 100 }"
                class="progress bg-accent1"
                [ngStyle]="{ width: item.progress + '%' }"
                style="height: 12px !important"
              >
                <div class="rad-pill bg-color3"></div>
              </div>
            </div>
            <ng-template [ngIf]="item.progress == 100" [ngIfElse]="notComplete">
              <div class="sub-text text-upper fg-accent1">Completed</div>
            </ng-template>
            <ng-template #notComplete>
              <div class="sub-text text-upper">
                {{ item.subTitle }}
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
