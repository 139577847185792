<app-page-navigation [page]="'tester'">
  <div class="tester-container d-flex align-items-center flex-wrap">
    <div class="d-flex flex-wrap justify-content-space-between">
      <h1 class="fg-accent1 tester-title text-upper w-100 mb-2">
        {{ media.name }}
      </h1>

      <div class="site-small">
        <app-video-small-rect [media]="media"></app-video-small-rect>
      </div>

      <div class="description text-justify mt-2">
        {{ media.description }}
      </div>
      <div class="site-medium">
        <app-video-small-square [media]="media"></app-video-small-square>
      </div>
      <div class="site-medium-large">
        <app-video-small-rect-lg [media]="media"></app-video-small-rect-lg>
      </div>

      <div class="w-100 d-flex justify-content-center mt-8 flex-wrap">
        <div
          class="horizontal-button-space d-flex justify-content-center mb-3 mx-2"
        >
          <app-donwload-button
            iconUrl="https://iforyou.ams3.cdn.digitaloceanspaces.com/apple.svg"
            title="Apple"
            color="accent1"
          iconAction="#"
            [download]="false"
          ></app-donwload-button>
        </div>

        <div class="horizontal-button-space d-flex justify-content-center mx-2">
          <app-donwload-button
            iconUrl="https://iforyou.ams3.cdn.digitaloceanspaces.com/google-play.svg"
            title="Google play"
            color="accent2"
             iconAction="#"
            [download]="false"
          ></app-donwload-button>
        </div>
      </div>
    </div>
  </div>
</app-page-navigation>
