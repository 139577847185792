<div class="d-flex flex-wrap side-nav-buttons justify-content-center">
  <a
  aria-label="Navigation"
    href="{{ '/#' + item.url }}"
    class="nav-item border border-color1"
    *ngFor="let item of navItems"
    (click)="activate(item)"
    [class]="item.color"
    [ngClass]="{
      'line-higlight': item.id < maxId,
      'line-higlight-dark': item.id < activeId,
      'line-higlight-light': item.id == activeId,
      'active-item': item.id == activeId,
      'no-background': item.id != activeId
    }"
  >
    <div class="d-flex justify-content-center align-items-center star">
      <span class="d-flex align-items-center justify-content-center">
        <span class="material-icons">
          {{ item.icon }}
        </span></span
      >
    </div>
  </a>
</div>
