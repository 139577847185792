import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-phone-vision',
  templateUrl: './phone-vision.component.html',
  styleUrls: ['./phone-vision.component.scss'],
})
export class PhoneVisionComponent implements OnInit {
  public media: MediaObject = new MediaObject(
    99,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  public mediaSet: boolean = false;
  private mediaEvent: Subscription;

  constructor(private event: EventService, private mediaService: MediaService) {
    this.mediaEvent = this.event.getVisionPhoneEvent().subscribe((media) => {
      this.media = media;
      this.mediaSet = true;
    });
  }

  private initMedia(): void {
    this.media = this.mediaService.getMediaVisionPage()[0];
    this.mediaSet = true;
  }

  ngOnInit(): void {
    this.initMedia();
  }
}
