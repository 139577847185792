import { Component, HostListener, OnInit } from '@angular/core';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { NavigationService } from '../core/services/navigationService';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor(private event: EventService, private nav: NavigationService) { }

  // listen for scroll event 
  @HostListener("mouseover")
  public callNav() {
    this.nav.setActiveNavItem(1);
  }

  ngOnInit(): void {
  }

}
