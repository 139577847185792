import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { NavigationService } from 'src/app/core/services/navigationService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-phone-home',
  templateUrl: './phone-home.component.html',
  styleUrls: ['./phone-home.component.scss'],
})
export class PhoneHomeComponent implements OnInit {
  @Input() animationDown: boolean = false;
  @Input() animationUp: boolean = false;
  isLoading: boolean = true;
  public homeMedia: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  public videosMedia: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  homeEvent: Subscription;
  videosEvent: Subscription;
  public indexPage: boolean = false;
  public playing: boolean = false;
  private navEvent: Subscription;

  constructor(
    private event: EventService,
    private media: MediaService,
    private nav: NavigationService
  ) {
    this.homeEvent = this.event.getHomePagePhoneEvent().subscribe((media) => {
      this.homeMedia = media;
      this.indexPage = true;
      this.playing = false;
    });

    this.videosEvent = this.event.getVideosPhoneEvent().subscribe((media) => {
      this.videosMedia = media;
      this.indexPage = false;

      if (media.collectionName != 'collection video home') {
        this.playing = true;
      }
    });

    this.event.getPlayVideoEvent().subscribe((video) => {
      setTimeout(() => {
        this.playing = true;
      }, 2000);
    });

    this.navEvent = this.nav.getActiveNavItem().subscribe((state) => {
      if (state != 0) {
        this.playing = false;
      }
    });
  }

  private timeAnimatoin(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 3500);
  }

  /*play icon trigger:
   * start video
   * enable controls
   * hide icon
   */
  public startPlaying(): void {
    this.playing = true;
  }

  private initMedia(): void {
    this.homeMedia = this.media.getMediaHome()[0];
  }
  ngOnInit(): void {
    this.initMedia();
    this.timeAnimatoin();
  }
}
