import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-vision-small',
  templateUrl: './vision-small.component.html',
  styleUrls: ['./vision-small.component.scss']
})
export class VisionSmallComponent implements OnInit {
  public mediaNav: MediaObject[] = [];
  public media: MediaObject = new MediaObject(0, "", "", "", "", "", "", "", "", "", "");
  private event: Subscription;

  constructor(private mediaService: MediaService, private eventService: EventService) {
    this.event = eventService.getVisionPhoneEvent().subscribe((media) => {
      this.media = media
    })
  }

  private initMedia(): void {
    this.mediaNav = this.mediaService.getMediaVisionPage();
    this.media = this.mediaNav[0];
  }
  ngOnInit(): void {
    this.initMedia();
  }

}
