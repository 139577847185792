import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePageMediumRoutingModule } from './home-page-medium-routing.module';
import { HomePageMediumComponent } from './home-page-medium.component';
import { SharedModule } from '../shared/shared.module';
import { SharedSmallModule } from '../shared-small/shared-small.module';
import { CoreModule } from '../core/core.module';


@NgModule({
  declarations: [
    HomePageMediumComponent
  ],
  imports: [
    CommonModule,
    HomePageMediumRoutingModule,
    SharedModule,
    SharedSmallModule,
    CoreModule
  ], exports: [
    HomePageMediumComponent
  ]
})
export class HomePageMediumModule { }
