<app-page-navigation [media]="mediaList" [page]="'testimonials'">
  <div class="testimonials-container d-flex align-items-center flex-wrap">
    <div>
      <h1 class="fg-accent1 testi-title text-upper">Testimonials:</h1>
    </div>
    <div class="d-flex justify-content-space-between media-content flex-wrap">
      <div class="media-testimonial-container">
        <img
          [src]="media.frameUrl"
          alt="WeDream rules"
          class="media-testimonial"
          loading="lazy" 
        />
      </div>
      <div class="testimonial-video-container">
        <div class="testimonial-video">
          <ng-template [ngIf]="!playing" [ngIfElse]="videoPlaying">
            <div
              class="play-cont d-flex justify-content-center align-items-center position-relative"
            >
              <img
                [src]="media.gifUrl"
                alt="WeDream rules"
                class="media-testimonial"
                loading="lazy" 
              />
              <span
                class="material-icons position-absolute play"
                (click)="play()"
              >
                play_circle_filled
              </span>
            </div>
          </ng-template>
          <ng-template #videoPlaying>
            <div class="media-testimonial play-cont">
              <video [src]="media.videoUrl" controls autoplay             
              ></video>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</app-page-navigation>
