import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { SharedComponent } from './shared.component';
import { PhoneComponent } from './phone/phone.component';
import { ImageTileComponent } from './image-tile/image-tile.component';
import { VideoTileComponent } from './video-tile/video-tile.component';
import { VerticalTileComponent } from './vertical-tile/vertical-tile.component';
import { VideoSquareComponent } from './video-square/video-square.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SideNavigationComponent } from './layout/side-navigation/side-navigation.component';
import { NavItemsComponent } from './layout/side-navigation/nav-items/nav-items.component';
import { SignUpButtonComponent } from './layout/side-navigation/sign-up-button/sign-up-button.component';
import { FillerComponent } from './filler/filler.component';
import { LogoComponent } from './images/logo/logo.component';
import { DonwloadButtonComponent } from './buttons/donwload-button/donwload-button.component';
import { PrivacyFooterComponent } from './privacy-footer/privacy-footer.component';

@NgModule({
  declarations: [
    SharedComponent,
    PhoneComponent,
    ImageTileComponent,
    VideoTileComponent,
    VerticalTileComponent,
    VideoSquareComponent,
    NavbarComponent,
    SideNavigationComponent,
    NavItemsComponent,
    SignUpButtonComponent,
    FillerComponent,
    LogoComponent,
    DonwloadButtonComponent,
    PrivacyFooterComponent,
  ],
  imports: [CommonModule, SharedRoutingModule],
  exports: [
    PhoneComponent,
    SharedComponent,
    PhoneComponent,
    ImageTileComponent,
    VideoTileComponent,
    VerticalTileComponent,
    VideoSquareComponent,
    NavbarComponent,
    SideNavigationComponent,
    NavItemsComponent,
    SignUpButtonComponent,
    FillerComponent,
    LogoComponent,
    DonwloadButtonComponent,
    PrivacyFooterComponent,
  ],
})
export class SharedModule {}
