<div class="site-large">
  <app-navbar></app-navbar>
  <app-site></app-site>
  <app-side-navigation></app-side-navigation>
  <div id="footer">
    <app-privacy-footer></app-privacy-footer>
  </div>
</div>

<div class="site-small">
  <app-site-small></app-site-small>
</div>
