<div class="home-page">
  <div class="home-page-content">
    <!-- title + logo layer -->
    <div class="d-flex flex-wrap body-top">
      <!-- title + logo layer -->

      <div class="home-title text-upper">
        Make
        <br />
        <span class="fg-accent1">Your</span>
        <br />
        dreams
        <br />
        happen
      </div>
      <div class="flex-grow-1 d-flex justify-content-center align-items-center">
        <div class="logo-image border rad-circle"></div>
      </div>
    </div>
    <div class="body-bottom">
      <div
        class="iforyou text-upper position-absolute d-flex flex-wrap justify-content-space-around"
      >
        <span class="fg-accent1">we</span><span>dream</span>
      </div>
      <div class="img-stack d-flex flex-wrap align-items-space-between">
        <div class="grid-cont">
          <div class="media" *ngFor="let item of media">
            <img [src]="item.gifUrl" alt="WeDream rules" loading="lazy" />
          </div>
        </div>

        <div
          class="d-flex justify-content-flex-end w-100 align-items-center but-cont"
        >
          <a
          aria-label="Download"

            href="/#becomeTester"
            class="btn btn-sm bg-accent1 fg-color2 button d-flex align-items-center justify-content-center"
            >download now</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
