import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestimonialsSmallRoutingModule } from './testimonials-small-routing.module';
import { TestimonialsSmallComponent } from './testimonials-small.component';
import { SharedSmallModule } from '../shared-small/shared-small.module';


@NgModule({
  declarations: [
    TestimonialsSmallComponent
  ],
  imports: [
    CommonModule,
    TestimonialsSmallRoutingModule,
    SharedSmallModule
  ],
  exports: [
    TestimonialsSmallComponent
  ]
})
export class TestimonialsSmallModule { }
