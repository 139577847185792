import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhasesSmallComponent } from './phases-small.component';

const routes: Routes = [{ path: '', component: PhasesSmallComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhasesSmallRoutingModule { }
