<div class="error-container">
  <div class="error-text fg-accent1 d-flex justify-content-center flex-wrap">
    <span class="error-placeholder w-100 text-center">Error</span>
    <span class="error-code">{{ error }}</span>
    <div class="d-flex justify-content-center w-100">
      <button class="btn btn-lg bg-gradient5 fg-color2" (click)="goBack()">
        Back
      </button>
    </div>
  </div>
</div>
