import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavObject } from 'src/app/models/navObject';
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  navItems: NavObject[] = [
    new NavObject(0, 'bg-gradient1', 'home', 'home'),
    // new NavObject(1, "bg-gradient2", "people", "testimonials"),
    new NavObject(1, 'bg-gradient6', 'remove_red_eye', 'vision'),
    new NavObject(2, 'bg-gradient4', 'incomplete_circle', 'phases'),
    new NavObject(3, 'bg-gradient5', 'maps_ugc', 'touch'),
  ];

  navItemsSmall: NavObject[] = [
    new NavObject(0, 'bg-gradient5', 'home', 'videos'),
    new NavObject(1, 'bg-gradient1', 'downloading', 'tester'),
    new NavObject(2, 'bg-gradient6', 'remove_red_eye', 'vision'),
    new NavObject(3, 'bg-gradient4', 'incomplete_circle', 'phases'),
    new NavObject(4, 'bg-gradient5', 'maps_ugc', 'social'),
  ];

  public getNavItems(): NavObject[] {
    return this.navItems;
  }

  public getNavItemsSmall(): NavObject[] {
    return this.navItemsSmall;
  }

  /*nav event*/
  private activeNavItem = new Subject<any>();
  public setActiveNavItem(id: number) {
    this.activeNavItem.next(id);
  }

  public getActiveNavItem(): Observable<any> {
    return this.activeNavItem.asObservable();
  }

  /*scroll event*/
  private enableScrollEvent = new Subject<boolean>();
  public setEnableScrollEvent(state: boolean) {
    this.enableScrollEvent.next(state);
  }

  public getEnableScrollEvent(): Observable<boolean> {
    return this.enableScrollEvent.asObservable();
  }

  /*reset homePage event*/
  private resetHomeEvent = new Subject<boolean>();
  public setResetHomeEvent(state: boolean) {
    this.resetHomeEvent.next(state);
  }

  public getResetHomeEvent(): Observable<boolean> {
    return this.resetHomeEvent.asObservable();
  }
}
