import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestimonialsRoutingModule } from './testimonials-routing.module';
import { TestimonialsComponent } from './testimonials.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { PhoneTestimonialsComponent } from './phone-testimonials/phone-testimonials.component';
import { VideoTilesTestimonialsComponent } from './video-tiles-testimonials/video-tiles-testimonials.component';
import { TestimonialsContentComponent } from './testimonials-content/testimonials-content.component';


@NgModule({
  declarations: [
    TestimonialsComponent,
    PhoneTestimonialsComponent,
    VideoTilesTestimonialsComponent,
    TestimonialsContentComponent
  ],
  imports: [
    CommonModule,
    TestimonialsRoutingModule,
    CoreModule,
    SharedModule
  ],
  exports: [
    TestimonialsComponent
  ]
})
export class TestimonialsModule { }
