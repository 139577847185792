import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhasesRoutingModule } from './phases-routing.module';
import { PhasesComponent } from './phases.component';
import { PhasesContentComponent } from './phases-content/phases-content.component';
import { PhonePhasesComponent } from './phone-phases/phone-phases.component';
import { VideoTilesPhasesComponent } from './video-tiles-phases/video-tiles-phases.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    PhasesComponent,
    PhasesContentComponent,
    PhonePhasesComponent,
    VideoTilesPhasesComponent
  ],
  imports: [
    CommonModule,
    PhasesRoutingModule,
    SharedModule,
    CoreModule
  ],
  exports: [
    PhasesComponent
  ]
})
export class PhasesModule { }
