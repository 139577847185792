export const environment = {
  firebase: {
    projectId: 'iforyou',
    appId: '1:779595534248:web:693f0309681efa55e0d79d',
    storageBucket: 'iforyou.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyC2zxbwH_CtavLhjOdkGAcmxgt8Gxz9Pmc',
    authDomain: 'iforyou.firebaseapp.com',
    messagingSenderId: '779595534248',
    measurementId: 'G-0MEY04HL9E',
  },
  production: true
};
