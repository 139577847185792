import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedSmallRoutingModule } from './shared-small-routing.module';
import { SharedSmallComponent } from './shared-small.component';
import { SideNavigationSmallComponent } from './side-navigation-small/side-navigation-small.component';
import { CirclesNavigationComponent } from './circles-navigation/circles-navigation.component';
import { HeaderNavigationComponent } from './header-navigation/header-navigation.component';
import { PageNavigationComponent } from './page-navigation/page-navigation.component';
import { VideoSmallRectComponent } from './video-small-rect/video-small-rect.component';
import { FillerSmallComponent } from './filler-small/filler-small.component';
import { VideoSmallRectLgComponent } from './video-small-rect-lg/video-small-rect-lg.component';
import { VideoSmallSquareComponent } from './video-small-square/video-small-square.component';
import { VideoSmallVerticalComponent } from './video-small-vertical/video-small-vertical.component';


@NgModule({
  declarations: [
    SharedSmallComponent,
    SideNavigationSmallComponent,
    CirclesNavigationComponent,
    HeaderNavigationComponent,
    PageNavigationComponent,
    VideoSmallRectComponent,
    FillerSmallComponent,
    VideoSmallRectLgComponent,
    VideoSmallSquareComponent,
    VideoSmallVerticalComponent
  ],
  imports: [
    CommonModule,
    SharedSmallRoutingModule
  ],
  exports: [
    SideNavigationSmallComponent,
    PageNavigationComponent,
    VideoSmallRectComponent,
    FillerSmallComponent,
    VideoSmallSquareComponent,
    VideoSmallRectLgComponent,
    VideoSmallVerticalComponent

  ]
})
export class SharedSmallModule { }
