import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { NavigationService } from '../core/services/navigationService';
import { FillerObject } from '../models/fillerObject';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit {
  public scrollDisabled: boolean = true;
  private event: Subscription;
  private scrollDisabledEvent: Subscription;
  public testerFiller: FillerObject = new FillerObject(
    0,
    '',
    '',
    '',
    '',
    '',
    ''
  );
  public homeFiller: FillerObject = new FillerObject(0, '', '', '', '', '', '');
  public testerText: any[] = [];

  constructor(
    private eventService: EventService,
    private mediaService: MediaService,
    private nav: NavigationService
  ) {
    this.event = this.eventService.getscrollEvent().subscribe((state) => {
      this.scrollDisabled = state;
    });

    this.scrollDisabledEvent = this.nav
      .getEnableScrollEvent()
      .subscribe((disabled) => {
        this.scrollDisabled = disabled;
      });
  }

  private initFillers(): void {
    this.homeFiller = this.mediaService.getHomeFiller();
    this.testerFiller = this.mediaService.getTesterFiller();
    this.testerText = this.mediaService.getMediaTesterPage();
  }

  ngOnInit(): void {
    this.initFillers();
  }
}
