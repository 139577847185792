export class MediaObject {
  id: number;
  collectionName: string;
  name: string;
  subName: string;
  description: string;
  toolTip: string;
  videoUrl: string;
  gifUrl: string;
  frameUrl: string;
  socialIcon: string;
  socialUrl: string;


  constructor(id: number,
    collectionName: string,
    name: string,
    subName: string,
    description: string,
    toolTip: string,
    videoUrl: string,
    gifUrl: string,
    frameUrl: string,
    socialIcon: string,
    socialUrl: string) {
    this.id = id;
    this.collectionName = collectionName;
    this.name = name;
    this.subName = subName;
    this.description = description;
    this.toolTip = toolTip;
    this.videoUrl = videoUrl;
    this.gifUrl = gifUrl;
    this.frameUrl = frameUrl;
    this.socialIcon = socialIcon;
    this.socialUrl = socialUrl;
  }
}
