import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestimonialsSmallComponent } from './testimonials-small.component';

const routes: Routes = [{ path: '', component: TestimonialsSmallComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestimonialsSmallRoutingModule { }
