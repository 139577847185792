import { Component, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-get-in-touch-small',
  templateUrl: './get-in-touch-small.component.html',
  styleUrls: ['./get-in-touch-small.component.scss']
})
export class GetInTouchSmallComponent implements OnInit {
  public mediaNav: MediaObject[] = [];
  constructor(private mediaService: MediaService) { }

  private initMedia(): void {
    this.mediaNav = this.mediaService.getMediaSocialPage();
  }
  ngOnInit(): void {
    this.initMedia();
  }

}
