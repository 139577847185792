import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetInTouchRoutingModule } from './get-in-touch-routing.module';
import { GetInTouchComponent } from './get-in-touch.component';
import { GetInTouchFormComponent } from './get-in-touch-form/get-in-touch-form.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    GetInTouchComponent,
    GetInTouchFormComponent
  ],
  imports: [
    CommonModule,
    GetInTouchRoutingModule,
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ], exports: [
    GetInTouchComponent,
    GetInTouchFormComponent
  ]
})
export class GetInTouchModule { }
