<div class="content-circle" [ngClass]="{ 'videos-spacing': page == 'videos' }">
  <div
    *ngFor="let item of media; let i = index"
    class="circle d-flex justify-content-center w-100"
    (click)="navigate(i)"
  >
    <ng-template [ngIf]="page == 'social'" [ngIfElse]="notSocial">
      <a [href]="item.socialUrl" target="_blank" rel="noopener noreferrer"  aria-label="media.name" >
        <img [src]="item.socialIcon" alt="WeDream rules" loading="lazy" />
      </a>
    </ng-template>
    <ng-template #notSocial>
      <img [src]="item.frameUrl" alt="WeDream rules" loading="lazy" />
    </ng-template>
  </div>
</div>
