import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';
import { ProgressObject } from 'src/app/models/progressObject';
import { ProgressType } from 'src/app/models/enums/progressType';

@Component({
  selector: 'app-phone-phases',
  templateUrl: './phone-phases.component.html',
  styleUrls: ['./phone-phases.component.scss'],
})
export class PhonePhasesComponent implements OnInit {
  private mediaEvent: Subscription;
  public media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  private rawProgress: ProgressObject[] = [];
  public progress: ProgressObject[] = [];
  public progressType: typeof ProgressType = ProgressType;

  constructor(private event: EventService, private mediaService: MediaService) {
    this.mediaEvent = this.event.getPhasesPhoneEvent().subscribe((media) => {
      this.media = media;
      this.filterProgress();
    });
  }

  private initMedia(): void {
    this.rawProgress = this.mediaService.getProgressPhasesPage();
  }
  private filterProgress(): void {
    this.progress = this.rawProgress.filter((p) => p.mediaId == this.media.id);
  }

  public calculateScore(score: number): string {
    const score2 = 407 - (407 * score) / 100;
    return `stroke-dashoffset:${score2}px!important`;
  }

  ngOnInit(): void {
    this.initMedia();
  }
}
