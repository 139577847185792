<ng-template [ngIf]="type == 'title'" [ngIfElse]="default">
  <div class="position-relative img-tile img-tile-padding">
    <div
      class="label position-absolute d-flex flex-wrap justify-content-between"
      *ngIf="title != ''"
    >
      <span class="fg-accent1 text-left">{{ subTitle }}</span>
    </div>

    <img [src]="media.gifUrl" alt="WeDream rules" class="shadow-hover-accent1" loading="lazy"  />

    <div
      class="circle-label position-absolute d-flex align-items-center justify-content-center fg-accent1 bg-color2"
    >
      <span>{{ number }}</span>
    </div>
  </div>
</ng-template>
<ng-template #default>
  <div class="images shadow-hover-accent1">
    <div class="img-container">
      <img
        [src]="media.gifUrl == '' ? media.frameUrl : media.gifUrl"
        [alt]="media.name"
        loading="lazy" 
      />
    </div>
    <ng-template [ngIf]="mediaFade">
      <div class="img-container" [ngClass]="showFade ? 'slideDown' : 'slideUp'">
        <img [src]="mediaFade?.gifUrl" alt="WeDream rules" class="" loading="lazy"  />
      </div>
    </ng-template>
  </div>
</ng-template>
