import { ProgressType } from './enums/progressType'
export class ProgressObject {
  id: number;
  mediaId: number;
  progress: number;
  title: string;
  subTitle: string;
  type: ProgressType;


  constructor(id: number,
    mediaId: number,
    progress: number,
    title: string,
    subTitle: string,
    type: ProgressType
  ) {
    this.id = id;
    this.mediaId = mediaId;
    this.progress = progress;
    this.title = title;
    this.subTitle = subTitle;
    this.type = type;
  }
}
