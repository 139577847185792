import { Component, Input, OnInit } from '@angular/core';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-vertical-tile',
  templateUrl: './vertical-tile.component.html',
  styleUrls: ['./vertical-tile.component.scss']
})
export class VerticalTileComponent implements OnInit {
  @Input() type: string = "";
  @Input() title: string = "Social";
  @Input() media: MediaObject = new MediaObject(0, "", "", "", "", "", "", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempgif.gif", "", "https://iforyou.ams3.cdn.digitaloceanspaces.com/facebookLogo.svg", "");


  constructor() { }

  ngOnInit(): void {
  }

}
