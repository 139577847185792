<div class="vision-body">
  <div
    class="d-flex flex-wrap justify-content-center align-items-center vision-body-content"
  >
    <div
      *ngFor="let item of media; let i = index"
      class="banner position-relative"
      (click)="setMediaPhone(item)"
      [ngClass]="{ 'order-1': i < 2, 'order-5': i > 1 }"
    >
      <ng-template [ngIf]="i == 0">
        <div class="text-upper fg-accent1 w-100 title-vision">
          <h1 class="vision-title">It’s all for you</h1>
        </div>
      </ng-template>

      <app-vertical-tile [title]="''" [media]="item"></app-vertical-tile>
    </div>
    <div class="d-flex justify-content-start align-items-start order-3">
      <div class="">
        <app-phone-vision></app-phone-vision>
      </div>
    </div>
  </div>
</div>
