<a [ngClass]="colorClass" class="button" [href]="iconAction"  aria-label="Download">
  <div class="my-1 d-flex flex-wrap justify-content-center align-items-center">
    <div class="logo">
      <img [src]="iconUrl" alt="WeDream rules" width="100%" height="100%" loading="lazy" />
    </div>

    <div class="d-flex flex-column fg-color2 d-flex justify-content-center">
      <!-- TODO: change this to download -->
      <span class="subTitle">{{download ? "Download now on": "Coming soon on"}}</span>
      <span class="title">{{ title }}</span>
    </div>
  </div>
</a>
