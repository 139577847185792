import { Component, Input, OnInit } from '@angular/core';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-video-small-rect-lg',
  templateUrl: './video-small-rect-lg.component.html',
  styleUrls: ['./video-small-rect-lg.component.scss']
})
export class VideoSmallRectLgComponent implements OnInit {
  playing: boolean = false;
  @Input() media: MediaObject = new MediaObject(0, "", "", "", "", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempgif.gif", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempgif.gif", "", "");
  @Input() type: string = "";

  constructor() { }


  public play(): void {
    this.playing = true;
  }

  ngOnChanges(): void {
    this.playing = false;
  }

  ngOnInit(): void {
  }

}
