<div class="content-container">
  <div class="d-flex justify-content-flex-end">
    <h1 class="content-sub-block phases-title fg-accent1 mb-2">Timeline</h1>
  </div>

  <div class="d-flex justify-content-flex-end">
    <app-video-tile [media]="media"></app-video-tile>
  </div>

  <div class="d-flex justify-content-flex-end">
    <div class="content-sub-block">
      <div class="phases-sub-title my-3">
        {{ media.name }}
      </div>
      <div class="text-justify">
        {{ media.description }}
      </div>
    </div>
  </div>
</div>
