import { Component, Input, OnInit } from '@angular/core';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-video-small-rect',
  templateUrl: './video-small-rect.component.html',
  styleUrls: ['./video-small-rect.component.scss']
})
export class VideoSmallRectComponent implements OnInit {
  playing: boolean = false;
  @Input() media: MediaObject = new MediaObject(0, "", "", "", "", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempvideo.mp4", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempgif.gif", "https://iforyou.ams3.cdn.digitaloceanspaces.com/tempgif.gif", "", "");
  constructor() { }



  public play(): void {
    this.playing = true;
  }

  /*reset play icon when media changes*/
  ngOnChanges(): void {
    this.playing = false;
  }

  ngOnInit(): void {
  }

}
