<div class="phone position-relative mx-5">
  <div class="svg-container">
    <svg
      #phone
      id="phone_frame"
      data-name="phone frame"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 300 638"
    >
      <g
        id="Rectangle_1071"
        data-name="Rectangle 1071"
        fill="none"
        stroke="#fff"
        stroke-width="5"
      >
        <rect width="300" height="638" rx="30" stroke="none" />
        <rect x="2.5" y="2.5" width="295" height="633" rx="27.5" fill="none" />
      </g>
      <g
        id="Rectangle_1072"
        data-name="Rectangle 1072"
        transform="translate(75)"
        fill="#fff"
        stroke="#fff"
        stroke-width="5"
      >
        <path
          d="M0,0H150a0,0,0,0,1,0,0V17a20,20,0,0,1-20,20H20A20,20,0,0,1,0,17V0A0,0,0,0,1,0,0Z"
          stroke="none"
        />
        <path
          d="M2.5,2.5h145a0,0,0,0,1,0,0V17A17.5,17.5,0,0,1,130,34.5H20A17.5,17.5,0,0,1,2.5,17V2.5A0,0,0,0,1,2.5,2.5Z"
          fill="none"
        />
      </g>
      <rect
        id="Rectangle_1073"
        data-name="Rectangle 1073"
        width="38"
        height="9"
        rx="4.5"
        transform="translate(121 14)"
      />
      <rect
        id="Rectangle_1074"
        data-name="Rectangle 1074"
        width="9"
        height="9"
        rx="4.5"
        transform="translate(171 14)"
      />
    </svg>
  </div>

  <div class="filler-phone position-absolute">
    <ng-content></ng-content>
  </div>
</div>
