
<div class="position-relative video-tile">

<ng-template [ngIf]="!played" [ngIfElse]="playing">
  <img [src]="media.gifUrl" alt="WeDream rules" loading="lazy" >
  <span class="material-icons position-absolute play" (click)="play()">
    play_circle_filled
  </span>
</ng-template>

<ng-template #playing>
 <div class="video">
  <video autoplay loop controls            
  [src]="media.videoUrl" type="video/mp4">
  </video>
 </div>
</ng-template>

</div>


