import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePageRoutingModule } from './home-page-routing.module';
import { HomePageComponent } from './home-page.component';
import { SharedModule } from '../shared/shared.module';
import { LoadingComponent } from './loading/loading.component';
import { PhoneHomeComponent } from './phone-home/phone-home.component';
import { VideoHomeComponent } from './video-home/video-home.component';
import { VideoTilesComponent } from './video-tiles/video-tiles.component';


@NgModule({
  declarations: [
    HomePageComponent,
    LoadingComponent,
    PhoneHomeComponent,
    VideoHomeComponent,
    VideoTilesComponent
  ],
  imports: [
    CommonModule,
    HomePageRoutingModule,
    SharedModule
  ],
  exports: [
    HomePageComponent
  ]
})
export class HomePageModule { }
