import { Component, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-home-page-small',
  templateUrl: './home-page-small.component.html',
  styleUrls: ['./home-page-small.component.scss']
})
export class HomePageSmallComponent implements OnInit {


  public media: MediaObject[] = [];

  constructor(private mediaService: MediaService) { }

  private initMedia(): void {
    this.media = this.mediaService.getMediaHome();
  }

  ngOnInit(): void {
    this.initMedia();
  }
}
