<div class="phone-phases testi-phone">
  <!-- phone container -->
  <app-phone>
    <div class="w-100 h-100 position-relative">
      <div class="h-100">
        <ng-template [ngIf]="progress.length > 0" [ngIfElse]="noProgress">
          <!-- titles -->
          <div class="mx-3 pt-8">
            <div>
              <h3 class="phases-phone-sub-title">PHASE {{ media.id }}</h3>
              <h1 class="phases-phone-title">{{ media.name }}</h1>
            </div>
            <!-- progress bars -->
            <div *ngFor="let item of progress; let i = index">
              <ng-template
                [ngIf]="item.type == progressType.CIRCLE"
                [ngIfElse]="bar"
              >
                <div class="d-flex flex-wrap justify-content-center my-3">
                  <div class="score score-accent1" data-test="5">
                    <svg height="150px" width="150px">
                      <circle
                        cx="50%"
                        cy="50%"
                        r="65px"
                        style="{{ calculateScore(item.progress) }}"
                      />
                    </svg>
                    <div
                      class="score-text d-flex flex-wrap justify-content-center"
                    >
                      {{ item.progress }} <span>%</span>
                    </div>
                  </div>
                  <div class="fw-700 col-60 text-center mb-2 complete-circle">
                    Completed
                  </div>
                </div>
              </ng-template>
              <ng-template #bar>
                <div class="mb-3">
                  <div>
                    <div class="">
                      <h2>{{ item.title }}</h2>
                    </div>
                    <ng-template
                      [ngIf]="item.progress == 100"
                      [ngIfElse]="notComplete"
                    >
                      <div class="complete fg-accent1 text-upper mb-1">
                        Completed
                      </div>
                    </ng-template>
                    <ng-template #notComplete>
                      <div class="complete text-upper mb-1">
                        {{ item.subTitle }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="progressbar" style="margin-top: 10px !important">
                    <div
                      [ngClass]="{ 'bgi-accent2': item.progress < 100 }"
                      class="progress bg-accent1"
                      [ngStyle]="{ width: item.progress + '%' }"
                    >
                      <div class="test rad-pill bg-color3"></div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <ng-template [ngIf]="media.id == 2">
              <div
                class="d-flex flex-wrap justify-content-center tester-button w-100"
              >
                <a href="/#tester" class="btn btn-lg bg-accent1 fg-color2"           aria-label="Download"

                  >Download now</a
                >
              </div>
            </ng-template>
          </div>
        </ng-template>

        <!-- fallback -->
        <ng-template #noProgress>
          <div
            class="d-flex flex-wrap justify-content-center align-items-center h-100"
          >
            <div class="d-flex justify-content-center flex-wrap">
              <!-- pop animation -->
              <div>
                <div class="we fg-color1">we</div>
                <div class="connect fg-accent1 my-3">connect</div>
                <div class="dreams fg-accent1">dreams</div>
              </div>
            </div>
            <div class="logo-container">
              <div class="logo-bottom">
                <app-logo></app-logo>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </app-phone>
</div>
