import { Component, HostListener, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { NavigationService } from '../core/services/navigationService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {

  public media: MediaObject[] = [];

  constructor(private mediaService: MediaService, private nav: NavigationService) { }

  @HostListener("mouseover")
  public callNav() {
    this.nav.setActiveNavItem(4);
  }

  private initMedia(): void {
    this.media = this.mediaService.getMediaSocialPage();
  }
  ngOnInit(): void {
    this.initMedia();
  }

}
