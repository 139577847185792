import { Component, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-home-page-medium',
  templateUrl: './home-page-medium.component.html',
  styleUrls: ['./home-page-medium.component.scss']
})
export class HomePageMediumComponent implements OnInit {

  public media: MediaObject[] = [];

  constructor(private mediaService: MediaService) { }

  private initMedia(): void {
    this.media = this.mediaService.getMediaHome();
  }

  ngOnInit(): void {
    this.initMedia();
  }

}
