<app-page-navigation [page]="'vision'" [media]="mediaNav">
  <div class="vision-container d-flex align-items-center flex-wrap">
    <div class="d-flex flex-wrap w-100">
      <h1 class="fg-accent1 vision-title text-upper w-100">It’s all for you</h1>
      <div class="video my-2 site-small">
        <app-video-small-square [media]="media"></app-video-small-square>
      </div>

      <div class="description">
        <h2 class="description-title mb-1">{{ media.name }}</h2>
        <p>
          {{ media.description }}
        </p>
      </div>

      <div class="site-medium justify-content-space-between">
        <app-video-small-vertical [media]="media"></app-video-small-vertical>
      </div>
    </div>
  </div>
</app-page-navigation>
