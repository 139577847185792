import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MediaObject } from 'src/app/models/mediaObject';
import { TestimonialObject } from 'src/app/models/testimonialObject';
@Injectable({
  providedIn: 'root',
})
export class EventService {
  /*navigation event*/
  private navigationEvent = new Subject<number>();

  public callnavigationEvent(index: number) {
    this.navigationEvent.next(index);
  }
  public getnavigationEvent(): Observable<number> {
    return this.navigationEvent.asObservable();
  }

  /*Title fade event*/
  private titleFadeEvent = new Subject<boolean>();
  callTitleFadeEvent(state: boolean) {
    this.titleFadeEvent.next(state);
  }
  public getTitleFadeEvent(): Observable<boolean> {
    return this.titleFadeEvent.asObservable();
  }

  /*homepage media phone*/
  private HomePagePhoneEvent = new Subject<MediaObject>();
  callHomePagePhoneEvent(media: MediaObject) {
    this.HomePagePhoneEvent.next(media);
  }

  public getHomePagePhoneEvent(): Observable<MediaObject> {
    return this.HomePagePhoneEvent.asObservable();
  }

  /*play home video*/
  private playVideoEvent = new Subject<boolean>();
  callPlayVideoEvent(trigger: boolean) {
    this.playVideoEvent.next(trigger);
  }

  public getPlayVideoEvent(): Observable<boolean> {
    return this.playVideoEvent.asObservable();
  }

  /*videos page  media phone*/
  private VideosPhoneEvent = new Subject<MediaObject>();

  callVideosPhoneEvent(media: MediaObject) {
    this.VideosPhoneEvent.next(media);
  }

  public getVideosPhoneEvent(): Observable<MediaObject> {
    return this.VideosPhoneEvent.asObservable();
  }

  /*testimonial page  media phone*/
  private TestimonialPhoneEvent = new Subject<MediaObject>();

  callTestimonialPhoneEvent(media: MediaObject) {
    this.TestimonialPhoneEvent.next(media);
  }

  public getTestimonialPhoneEvent(): Observable<MediaObject> {
    return this.TestimonialPhoneEvent.asObservable();
  }

  /*phases page  media phone*/
  private PhasesPhoneEvent = new Subject<MediaObject>();

  callPhasesPhoneEvent(media: MediaObject) {
    this.PhasesPhoneEvent.next(media);
  }

  public getPhasesPhoneEvent(): Observable<MediaObject> {
    return this.PhasesPhoneEvent.asObservable();
  }

  /*vision page  media phone*/
  private VisionPhoneEvent = new Subject<MediaObject>();

  callVisionPhoneEvent(media: MediaObject) {
    this.VisionPhoneEvent.next(media);
  }

  public getVisionPhoneEvent(): Observable<MediaObject> {
    return this.VisionPhoneEvent.asObservable();
  }

  /*scroll event page*/
  private scrollEvent = new Subject<boolean>();

  public callscrollEvent(state: boolean) {
    this.scrollEvent.next(state);
  }

  public getscrollEvent(): Observable<boolean> {
    return this.scrollEvent.asObservable();
  }
}
