<div class="testimonial-body">
  <div class="d-flex justify-content-center align-items-center testimonial-body-content ">
      
  <app-testimonials-content></app-testimonials-content>
  
    <div class="d-flex justify-content-start align-items-start">
      <div class="">
        <app-phone-testimonials></app-phone-testimonials>
      </div>
    </div>
  
    <div class="fade-block">
       <app-video-tiles-testimonials></app-video-tiles-testimonials>
    </div>
  </div>
  
</div>