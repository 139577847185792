import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageMediumComponent } from './home-page-medium.component';

const routes: Routes = [{ path: '', component: HomePageMediumComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageMediumRoutingModule { }
