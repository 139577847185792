import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VisionSmallRoutingModule } from './vision-small-routing.module';
import { VisionSmallComponent } from './vision-small.component';
import { SharedSmallModule } from '../shared-small/shared-small.module';
import { CoreModule } from '../core/core.module';


@NgModule({
  declarations: [
    VisionSmallComponent
  ],
  imports: [
    CommonModule,
    VisionSmallRoutingModule,
    CoreModule,
    SharedSmallModule
  ], exports: [
    VisionSmallComponent
  ]
})
export class VisionSmallModule { }
