<div class="tester-body">
  <div
    class="d-flex justify-content-center align-items-center tester-body-content"
  >
    <div class="content-block">
      <div class="position-relative">
        <h1 class="position-absolute fg-accent1 text-upper tester-title">
          {{ media.name }}
        </h1>
      </div>
      <div class="mb-4">
        {{ media.description }}
      </div>
      <div class="w-100 d-flex justify-content-center mt-8">
        <div class="mr-2">
          <app-donwload-button
            iconUrl="https://iforyou.ams3.cdn.digitaloceanspaces.com/apple.svg"
            title="Apple"
            color="accent1"
            iconAction="#"
            [download]="false"
          ></app-donwload-button>
        </div>

        <div class="ml-2">
          <app-donwload-button
            iconUrl="https://iforyou.ams3.cdn.digitaloceanspaces.com/google-play.svg"
            title="Google play"
            color="accent2"
            iconAction="#"
            [download]="false"
          ></app-donwload-button>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-start h-100 content-block">
      <app-video-square [media]="media"></app-video-square>
    </div>
  </div>
</div>
