import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../core/services/navigationService';

@Component({
  selector: 'app-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss']
})
export class PhasesComponent implements OnInit {

  constructor(private nav: NavigationService) { }

  @HostListener("mouseover")
  public callNav() {
    this.nav.setActiveNavItem(3);
  }

  ngOnInit(): void {

  }


}
