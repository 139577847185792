<div class="phases-body">
  <div class="d-flex justify-content-center align-items-center phases-body-content ">
      
  <app-phases-content></app-phases-content>
  
    <div class="d-flex justify-content-start align-items-start">
      <div class="">
        <app-phone-phases></app-phone-phases>
      </div>
    </div>
  
    <div class="fade-block">
       <app-video-tiles-phases></app-video-tiles-phases>
    </div>
  </div>
  
</div>