import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-phases-content',
  templateUrl: './phases-content.component.html',
  styleUrls: ['./phases-content.component.scss'],
})
export class PhasesContentComponent implements OnInit {
  private mediaEvent: Subscription;
  public playing: boolean = false;
  public media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );

  constructor(private event: EventService, private mediaService: MediaService) {
    this.mediaEvent = this.event.getPhasesPhoneEvent().subscribe((media) => {
      this.media = media;
      this.playing = false;
    });
  }

  private initMedia(): void {
    this.media = this.mediaService.getMediaPhasesPage()[0];
  }
  ngOnInit(): void {
    this.initMedia();
  }
}
