<div class="position-relative media-cont">
  <ng-template [ngIf]="!playing" [ngIfElse]="videoPlaying">
    <img [src]="media.gifUrl" alt="WeDream rules" loading="lazy" >  
    <span class="material-icons position-absolute play" (click)="play()">
      play_circle_filled
    </span>
  </ng-template>
  <ng-template #videoPlaying>
    <video [src]="media.videoUrl" controls             
    ></video>
  </ng-template> 
</div>