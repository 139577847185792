import { Component, Input, OnInit } from '@angular/core';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss']
})
export class PageNavigationComponent implements OnInit {
  @Input() media: MediaObject[] = [];
  @Input() page: string = 'videos';

  constructor() { }

  ngOnInit(): void {
  }

}
