<div class="overflw">
  <!-- <div class="overflow"> -->
  <div class="home-small">
    <app-home-page-small></app-home-page-small>
  </div>
  <div class="home-medium">
    <app-home-page-medium></app-home-page-medium>
  </div>

  <app-side-navigation-small>
    <!-- content block + navigation  -->
  </app-side-navigation-small>

  <div class="content-size">
    <app-videos-small></app-videos-small>
    <app-filler-small [fillerContent]="homeFiller"></app-filler-small>
    <!-- <app-testimonials-small></app-testimonials-small> -->
    <div id="becomeTester">
      <app-tester-small [media]="testerText[0]"></app-tester-small>
    </div>
    <app-vision-small></app-vision-small>
    <app-filler-small [fillerContent]="testerFiller"></app-filler-small>
    <app-phases-small></app-phases-small>
    <app-tester-small [media]="testerText[1]"></app-tester-small>
  </div>
  <app-get-in-touch-small></app-get-in-touch-small>
  <div class="d-flex justify-content-flex-end" id="footer">
    <app-privacy-footer></app-privacy-footer>
  </div>
</div>
