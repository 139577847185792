
 <div class="videos">
  <app-page-navigation [media]="mediaNav" [page]="'videos'">
    <div class="videos-container d-flex align-items-center flex-wrap">
      <div class="spacer"></div>
     <div>
      <h1 class="fg-accent1 video-title text-upper">{{media.name}}</h1>
      <div class="video my-2">
          <app-video-small-rect [media]="media"></app-video-small-rect>
      </div>
      <div class="text-container text-justify">
        {{media.description}}
      </div>
     </div>
    </div>
  </app-page-navigation>
 </div>

