export class FillerObject {
  id: number;
  preTitle1: string;
  highlight1: string;
  afterTitle1: string;

  preTitle2: string;
  highlight2: string;
  afterTitle2: string;


  constructor(id: number,
    preTitle1: string,
    highlight1: string,
    afterTitle1: string,
    preTitle2: string,
    highlight2: string,
    afterTitle2: string

  ) {
    this.id = id;
    this.preTitle1 = preTitle1;
    this.afterTitle1 = afterTitle1;
    this.highlight1 = highlight1;
    this.preTitle2 = preTitle2;
    this.afterTitle2 = afterTitle2;
    this.highlight2 = highlight2;
  }
}
