<div
  class="position-relative vertical-banner"
  [ngClass]="{ 'title-margin': title != '' }"
>
  <div class="label-title position-absolute" *ngIf="title != ''">
    <span class="fg-color1">{{ title }}</span>
  </div>
  <ng-template [ngIf]="type == 'social'" [ngIfElse]="noSocial">
    <a [href]="media.socialUrl" target="_blank" rel="noopener noreferrer"           aria-label="media.name"
    >
      <img [src]="media.gifUrl" alt="WeDream rules" class="shadow-hover-accent1" loading="lazy" />
    </a>
  </ng-template>
  <ng-template #noSocial>
    <img [src]="media.gifUrl" alt="social url" class="shadow-hover-accent1" loading="lazy" />
  </ng-template>

  <div class="label position-absolute">
    <span class="fg-accent1">{{ media.name }}</span>
  </div>
  <div class="circle-label position-absolute" *ngIf="type == 'social'">
    <img [src]="media.socialIcon" alt="WeDream rules" loading="lazy"  />
  </div>
</div>
