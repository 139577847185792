import { Component, Input, OnInit } from '@angular/core';
import { FillerObject } from 'src/app/models/fillerObject';

@Component({
  selector: 'app-filler-small',
  templateUrl: './filler-small.component.html',
  styleUrls: ['./filler-small.component.scss']
})
export class FillerSmallComponent implements OnInit {
  @Input() fillerContent: FillerObject = new FillerObject(0, "for", "young", "poeple", "by", "young", "poeple",);

  constructor() { }

  ngOnInit(): void {
  }

}
