import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-videos-small',
  templateUrl: './videos-small.component.html',
  styleUrls: ['./videos-small.component.scss'],
})
export class VideosSmallComponent implements OnInit {
  public media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  public mediaNav: MediaObject[] = [];
  private event: Subscription;

  constructor(
    private mediaService: MediaService,
    private eventService: EventService
  ) {
    this.event = eventService.getVideosPhoneEvent().subscribe((media) => {
      this.media = media;
    });
  }

  private initMedia(): void {
    this.mediaNav = this.mediaService.getmediaVideoPage();
    this.media = this.mediaService.getVideoHome();
  }
  ngOnInit(): void {
    this.initMedia();
  }
}
