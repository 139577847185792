<app-page-navigation [page]="'phases'" [media]="mediaNav">
  <div class="phases-container">
    <h1 class="fg-accent1 phases-title text-upper w-100">Timeline</h1>
    <div>
      <!-- <p class="phases-sub-title text-upper">{{ media.name }}</p> -->
      <h1 class="phases-title text-upper mb-2">{{ media.name }}</h1>
    </div>
    <div class="d-flex flex-wrap">
      <div class="content mb-2 site-medium">
        <app-video-small-square [media]="media"></app-video-small-square>
      </div>
      <div class="site-medium-large">
        <app-video-small-rect-lg
          [media]="media"
          [type]="'phase'"
        ></app-video-small-rect-lg>
      </div>
      <div class="progress-cont">
        <app-phases-content-small [media]="media"></app-phases-content-small>
      </div>
    </div>

    <div class="description mt-2">
      <p>
        {{ media.description }}
      </p>
    </div>
    <div class="video mt-2 site-small">
      <app-video-small-rect [media]="media"></app-video-small-rect>
    </div>
  </div>
</app-page-navigation>
