import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';
import { TestimonialObject } from '../models/testimonialObject';

@Component({
  selector: 'app-testimonials-small',
  templateUrl: './testimonials-small.component.html',
  styleUrls: ['./testimonials-small.component.scss'],
})
export class TestimonialsSmallComponent implements OnInit {
  public media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  public mediaList: MediaObject[] = [];
  private event: Subscription;
  public playing: Boolean = false;

  constructor(
    private mediaService: MediaService,
    private eventService: EventService
  ) {
    this.event = eventService.getTestimonialPhoneEvent().subscribe((media) => {
      this.media = media;
      this.playing = false;
    });
  }

  private initMedia(): void {
    this.mediaList = this.mediaService.getMediaTestimonialPageSmall();
    this.media = this.mediaList[0];
  }

  public play(): void {
    this.playing = true;
  }

  ngOnInit(): void {
    this.initMedia();
  }
}
