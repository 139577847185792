import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gdpr-page',
  templateUrl: './gdpr-page.component.html',
  styleUrls: ['./gdpr-page.component.scss'],
})
export class GdprPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
