import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-video-home',
  templateUrl: './video-home.component.html',
  styleUrls: ['./video-home.component.scss']
})
export class VideoHomeComponent implements OnInit {
  public homeVideo: MediaObject = new MediaObject(0, "", "", "", "", "", "", "", "", "", "");
  @Output() animationTrigger: EventEmitter<boolean> = new EventEmitter();
  constructor(private media: MediaService) { }

  private getHomeVideo(): void {
    this.homeVideo = this.media.getVideoHome();
  }
  ngOnInit(): void {
    this.getHomeVideo();
  }

  public triggerAnimation(): void {
    this.animationTrigger.emit()
  }
}
