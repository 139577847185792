import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-phases-small',
  templateUrl: './phases-small.component.html',
  styleUrls: ['./phases-small.component.scss']
})
export class PhasesSmallComponent implements OnInit {
  private mediaEvent: Subscription;
  public media: MediaObject = new MediaObject(0, "", "", "", "", "", "", "", "", "", "");
  public mediaNav: MediaObject[] = [];

  constructor(private event: EventService, private mediaService: MediaService) {
    this.mediaEvent = this.event.getPhasesPhoneEvent().subscribe((media) => {
      this.media = media;
    })
  }

  private initMedia(): void {
    this.mediaNav = this.mediaService.getMediaPhasesPage();
    this.media = this.mediaNav[0];
  }
  ngOnInit(): void {
    this.initMedia();
  }

}
