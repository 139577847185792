export class NavObject {
  id: number;
  color: string;
  icon: string;
  url: string;

  constructor(id: number,
    color: string,
    icon: string,
    url: string,
  ) {
    this.id = id;
    this.color = color;
    this.icon = icon;
    this.url = url;
  }
}
