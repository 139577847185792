<div class="cont">
  <div id="home">
    <app-home-page></app-home-page>
  </div>
  <div>
    <app-filler [fillerContent]="homeFiller"></app-filler>
    <!-- <div id="testimonials">
      <app-testimonials></app-testimonials>
    </div> -->
    <div id="vision">
      <app-vision></app-vision>
    </div>
    <app-filler [fillerContent]="testerFiller"></app-filler>
    <div id="tester">
      <app-tester [media]="testerText[0]"></app-tester>
    </div>
    <div id="phases">
      <app-phases></app-phases>
    </div>

    <app-tester [media]="testerText[1]"></app-tester>

    <div id="touch">
      <app-get-in-touch></app-get-in-touch>
    </div>
  </div>
</div>
