import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { SiteSmallModule } from './site-small/site-small.module';
import { SiteModule } from './site/site.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { PrivacyModule } from './privacy/privacy.module';
import { SiteRootComponent } from './site-root/site-root.component';
import { Error404Component } from './errorPages/error404/error404.component';
import { ErrorPageComponent } from './errorPages/error-page/error-page.component';
import { Error500Component } from './errorPages/error500/error500.component';
import { Error403Component } from './errorPages/error403/error403.component';

@NgModule({
  declarations: [AppComponent, SiteRootComponent, Error404Component, ErrorPageComponent, Error500Component, Error403Component],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    SiteModule,
    SiteSmallModule,
    PrivacyModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [ScreenTrackingService],
  bootstrap: [AppComponent],
})
export class AppModule {}
