<div class="position-relative media-cont bg-accnt1" [ngClass]="{'phases-media' : type == 'phase'}">
  <ng-template [ngIf]="!playing" [ngIfElse]="videoPlaying">
    <img [src]="media.gifUrl" alt="WeDream rules" loading="lazy" >  
    <span class="material-icons position-absolute play" (click)="play()">
      play_circle_filled
    </span>
  </ng-template>
  <ng-template #videoPlaying>
    <video [src]="media.videoUrl" muted="false" controls autoplay            
    ></video>
  </ng-template> 
</div>