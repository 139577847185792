<div
  class="privacy-container"
  oncopy="return false"
  oncut="return false"
  onpaste="return false"
>
  <div class="privacy-content">
    <h1 class="text-upper title">Privacy</h1>
    <h2>GDPR</h2>
    <div class="my-5 fg-color6">
      <h3 class="mb-3 fg-color1">GDPR I FOR YOU 2022</h3>
      <div>
        Introductie I For You (vanaf nu omschreven als IFY) is een onderdeel van
        Katz NV. IFY beheert de app I For You. Uw privacy is belangrijk De
        missie van IFY is om mensen samen te brengen en om relaties op te bouwen
        en te onderhouden via dromen. Hierbij willen we de grootst mogelijke
        transparantie aan de dag leggen en daarom zullen we in dit privacybeleid
        uiteenzetten welke gegevens we van u verzamelen en hoe we deze gebruiken
        en delen. Daarnaast wijzen we u ook op de privacy rechten die u ten alle
        tijden kan uitoefenen. Waarop is dit privacybeleid van toepassing? Dit
        Privacybeleid - en toekomstige updates ervan - is van toepassing op
        IForYou.com en alle andere aan IForYou-gerelateerde websites, apps,
        berichten en services. Het is van toepassing op het verzamelen,
        gebruiken en delen van persoonsgegevens van niet-geregistreerde
        gebruikers (hierna: “bezoekers) en geregistreerde gebruikers (hierna:
        “leden”). Wat als dit Privacybeleid gewijzigd wordt? IFY kan dit
        Privacybeleid aanpassen. Als het gaat om wezenlijke wijzigingen, wordt u
        hiervan op de hoogte gesteld, zodat u de kans heeft de wijzigingen door
        te nemen voordat ze in werking treden. Als u niet akkoord gaat met de
        wijziging, kan u uw account verwijderen. Als u de services blijft
        gebruiken na een kennisgeving van de wijzigingen, erkent u dat het
        verzamelen, gebruiken en delen van persoonsgegevens onder het gewijzigde
        Privacybeleid vallen vanaf de ingangsdatum. Wie is de databeheerder en
        contractspartij? De verwerkingsverantwoordelijke die de persoonsgegevens
        die worden verwerkt via IForYou beheert, is Katz N.V. Als
        verwerkingsverantwoordelijke neemt Katz N.V. passende technische en
        organisatorische maatregelen om te waarborgen en te kunnen aantonen dat
        de verwerking in overeenstemming met de geldende reglementering wordt
        uitgevoerd. Externe partners voor de verwerking van gegevens, Google,
        Cloudflare en Expo, handelen steeds volgens de door henzelf ingestelde
        GDPR regels. (deze regels zijn na te lezen op hun respectievelijke
        sites: ….. ) Wat wordt er gedeeld en met wie? Leden delen hun dromen en
        communiceren met het netwerk. Ze wisselen kennis en diverse inzichten
        uit en plaatsen en beoordelen relevante content. De content van sommige
        van onze Services zichtbaar voor bezoekers. (Services zoals beschreven
        in punt 4.1) Welke persoonsgegevens verzamelen we? 2.1. De door u aan
        ons verstrekte gegevens Een eerste categorie persoonsgegevens die we
        verzamelen zijn de persoonsgegevens die u aan ons verstrekt. Registratie
        Om een account te kunnen aanmaken, dient u enkele persoonsgegevens te
        verstrekken. Het gaat om uw naam, e-mailadres, telefoonnummer, leeftijd
        en een wachtwoord. Profiel Hoewel er geen verplichting bestaat bepaalde
        gegevens te verstrekken, kan u meer halen uit onze services mét
        bijkomende profielgegevens. U kan bijvoorbeeld eenvoudiger gevonden
        worden wat de kansen op connecties aanzienlijk vergroot. U bepaalt zelf
        of u gevoelige gegevens opneemt in uw profiel. Het is evenwel verstandig
        geen gegevens toe te voegen die u niet algemeen bekend wil maken.
        Plaatsen en uploaden We verzamelen uw persoonsgegevens wanneer u deze
        verstrekt aan, plaatst op en uploadt naar onze services, bijvoorbeeld
        wanneer u vragenlijsten of formulieren invult op onze services. U bent
        niet verplicht persoonsgegevens te plaatsen of te uploaden. Als u er
        echter voor kiest dit niet te doen, zal het niet mogelijk zijn uw
        netwerk uit te breiden en te benutten via onze services. 2.2. Gegevens
        van anderen Content en nieuws Uzelf en anderen kunnen content plaatsen
        op onze Services waarin gegevens over u vervat liggen (als onderdeel van
        artikelen, bijdragen, commentaar en video’s). We verzamelen deze
        openbare gegevens over u en maken deze beschikbaar als onderdeel van
        onze Services. Contact- en agendagegevens We ontvangen persoonlijke
        gegevens (met inbegrip van contactgegevens) over u wanneer anderen deze
        importeren of synchroniseren met onze Services, hun contacten koppelen
        aan profielen van Leden, visitekaartjes scannen en uploaden of berichten
        verzenden via onze Services (met inbegrip van uitnodigingen of
        connecties). Als u of anderen aangeven dat ze hun e-mailaccount willen
        synchroniseren met onze Services, verzamelen we ook gegevens in de
        ‘e-mailheaders’ die we kunnen koppelen aan profielen van Leden. Partners
        We ontvangen mogelijk persoonlijke gegevens (bijvoorbeeld uw
        functietitel en andere e-mailadressen) over u wanneer u de services van
        onze klanten en partners gebruikt. Gerelateerde bedrijven en andere
        services We ontvangen gegevens over u wanneer u gebruik maakt van
        sommige andere services die worden geleverd door ons of onze gelieerde
        ondernemingen, met inbegrip van Microsoft. 2.3. Gebruik van de service
        We registreren gebruiksgegevens wanneer u onze services bezoekt of
        anderszins gebruikt, zoals wanneer u content of bekendmakingen bekijkt
        of erop klikt, wanneer u een zoekopdracht uitvoert, wanneer u één van
        onze mobiele apps installeert of bijwerkt, wanneer u artikels deelt of
        reageert op content. We gebruiken hierbij aanmeldingsgegevens, cookies,
        apparaatgegevens en IP-adressen. 2.4. Cookies en soortgelijke
        technologieën We gebruiken cookies en soortgelijke technologieën om
        gegevens te verzamelen om u en uw apparaten te herkennen wanneer u
        contact hebt of heeft gehad met onze services. Daarnaast staan we toe
        dat sommige anderen onze cookies gebruiken. Regio’s en landen buiten
        België beschikken mogelijk over aanvullende rechten onder hun lokale
        wetgeving. Gelieve er bij het ter beschikking stellen van je gegevens
        mee rekening te houden dat we de wetgeving van België toepassen. 2.5. Uw
        apparaat en locatie Als u onze Services bezoekt of verlaat, ontvangen we
        de URL van zowel de website waar u vandaan kwam als de website waar u
        naartoe gaat en het moment van uw bezoek. Verder ontvangen we gegevens
        over uw mobiele netwerk en apparaat (bijvoorbeeld IP-adres, proxyserver,
        besturingssysteem, webbrowser en invoegtoepassingen, apparaat-id en
        -functies, cookie-id’s en/of uw mobiele aanbieder). Als u onze Services
        gebruikt vanaf een mobiel apparaat, stuurt dit apparaat ons gegevens
        over uw locatie op basis van uw telefooninstellingen. We vragen u om u
        hiervoor aan te melden voordat we uw exacte locatie bepalen met GPS of
        andere tools. Deze locatietoepassing kan geweigerd worden. 2.6.
        Berichten We verzamelen gegevens over u als u in verband met onze
        Services berichten verzendt, ontvangt of gebruikt. Als u bijvoorbeeld
        een connectieverzoek ontvangt op IForYou, volgen we of u op het verzoek
        hebt gereageerd en sturen we u herinneringen. We gebruiken ook
        technologie voor automatische scans voor berichten om onze applicatie te
        ondersteunen en te beschermen. We gebruiken deze technologie
        bijvoorbeeld om mogelijke reacties op berichten voor te stellen en om
        content die in strijd is met onze Gebruikersovereenkomst of
        Beleidslijnen voor de professionele community te beheren of te blokkeren
        van onze Services. 2.7. Sites en services van anderen We ontvangen
        gegevens over uw bezoeken en interactie met services van anderen wanneer
        u zich aanmeldt bij IForYou of services van derden die sommige van onze
        plug-ins, aanbiedingen, cookies of soortgelijke technologieën bevatten.
        2.8. Overig Onze Services zijn dynamisch: we ontwikkelen vaak nieuwe
        functies waarvoor we mogelijk nieuwe gegevens moeten verzamelen. Als we
        wezenlijk andere persoonsgegevens verzamelen of wezenlijk wijzigen hoe
        we deze gegevens verzamelen, gebruiken of delen, stellen we u hiervan op
        de hoogte en kan ook dit Privacybeleid worden aangepast. Hoe gebruiken
        we deze persoonsgegevens? Hoe we uw persoonsgegevens gebruiken, hangt af
        van de Services die u gebruikt, hoe u deze Services gebruikt en de
        keuzes die u maakt in uw instellingen. We gebruiken de gegevens die we
        over u hebben om onze Services te leveren en te personaliseren, onder
        andere met behulp van geautomatiseerde systemen en afgeleide informatie,
        zodat onze Services (met inbegrip van aanbiedingen) relevanter en
        nuttiger kunnen zijn voor u en anderen. 3.1. Services We gebruiken uw
        gegevens om toegang tot onze Services te faciliteren en uw instellingen
        te respecteren. Contact Met onze Services kunt u contact houden en op de
        hoogte blijven van andere gebruikers. Hiervoor kunt u een connectie
        maken met de gewenste individuen die ook een connectie met u willen
        maken. We gebruiken uw gegevens (zoals uw profiel, profielen die u hebt
        bekeken of gegevens die u hebt verstrekt) om uw profiel te helpen
        matchen. U kan ons ook toestemming geven om uw exacte locatie of
        nabijheid tot anderen te gebruiken voor bepaalde zaken (bijvoorbeeld om
        andere Leden bij u in de buurt te vinden die een of ander evenement
        bijwonen). U kiest zelf of u iemand wil uitnodigen voor onze Services,
        een connectieverzoek wil sturen of een ander Lid de mogelijkheid wil
        bieden om uw connectie te worden. Wanneer u iemand uitnodigt om een
        connectie met u aan te gaan, bevat de uitnodiging informatie over uw
        netwerk en basisprofielgegevens (zoals naam, profielfoto, functie en
        regio). We sturen herinneringen voor de uitnodiging naar de persoon die
        u hebt uitgenodigd. U kan kiezen of u uw eigen lijst met connecties al
        dan niet wilt delen met uw connecties. Bezoekers kunnen kiezen hoe we
        hun gegevens mogen gebruiken. Blijf op de hoogte Met onze Services kunt
        u op de hoogte blijven van nieuwsberichten, evenementen en ideeën over
        interessante onderwerpen uit uw vak/interessegebied, afkomstig van
        personen die u respecteert. We gebruiken de gegevens die we over u
        hebben (bijvoorbeeld gegevens die u verstrekt, gegevens die we
        verzamelen tijdens uw interactie met onze Services en informatie die we
        afleiden uit de gegevens waarover we beschikken) om onze Services voor u
        te personaliseren en te verbeteren. We gebruiken ook de gegevens die we
        over u hebben, om suggesties te doen voor extra’s die u kan toevoegen
        aan uw profiel en vaardigheden die u mogelijk nodig hebt in de toekomst.
        Als u aanpast dat u geïnteresseerd bent in een nieuwe vaardigheid of
        droom, gebruiken we deze gegevens om content in uw feed te
        personaliseren, suggesties te doen voor het volgen van bepaalde leden op
        onze applicaties, of gerelateerde content te bekijken. We kunnen uw
        content gebruiken, activiteiten en andere gegevens, waaronder uw naam en
        foto, om berichten naar uw netwerk en anderen te verzenden. Afhankelijk
        van uw instellingen kunnen we bijvoorbeeld anderen op de hoogte stellen
        dat u uw profiel hebt bijgewerkt, content hebt geplaatst, een sociale
        actie hebt uitgevoerd, een functie hebt gebruikt, nieuwe connecties hebt
        gemaakt of in het nieuws bent genoemd. Productiviteit Via onze Services
        kunt u met andere leden en gebruikers samenwerken. Via onze Services
        kunt u met andere leden communiceren en mogelijk ontmoetingen met hen
        plannen en voorbereiden. 3.2. Communicatie We nemen contact met u op via
        e-mail, mobiele telefoon, kennisgevingen op onze applicaties of in onze
        apps en andere methoden die beschikbaar zijn via de Services, waaronder
        sms-berichten en pushmeldingen. We kunnen u berichten sturen over de
        beschikbaarheid van onze Services, beveiliging of andere
        servicegerelateerde kwesties. We kunnen u ook berichten sturen over het
        gebruik van onze Services, netwerkupdates, herinneringen, andere dromen
        en reclameboodschappen van ons en onze partners. U kan uw
        communicatievoorkeuren op elk gewenst moment wijzigen. Hou er rekening
        mee dat u zich niet kunt afmelden voor de ontvangst van onze
        serviceberichten, waaronder beveiliging en juridische kennisgevingen. We
        maken ook communicatie tussen u en anderen mogelijk via onze Services
        bijvoorbeeld via uitnodigingen, InMail, groepen en berichten tussen
        connecties. 3.3. Reclame Advertenties zijn gericht op gebruikers, Leden,
        Bezoekers en anderen en gebruiken hierbij de volgende gegevens,
        afzonderlijk of in combinatie: ● Gegevens van reclametechnologieën
        binnen en buiten onze Services, pixels, advertentietags, cookies en
        apparaat-id’s; ● Door leden of gebruikers verstrekte gegevens; ●
        Gegevens over uw gebruik van onze Services zoals beschreven in artikel
        2.3; ● Informatie van advertentiepartners, leveranciers en uitgevers; en
        ● Informatie die van bovenstaande gegevens is afgeleid We tonen u
        advertenties die gesponsorde content worden genoemd en eruitzien als
        niet-gesponsorde content, maar toch als advertentie zijn aangemerkt
        (bijvoorbeeld ‘advertentie’ of ‘gesponsord’). Als u iets doet met deze
        advertenties (door deze bijvoorbeeld te markeren als interessant, te
        becommentariëren of te delen), wordt uw actie gekoppeld aan uw naam en
        is deze zichtbaar voor anderen, waaronder de adverteerder. Afhankelijk
        van uw instellingen, als u een sociale actie uitvoert op de
        IForYou-Services, kan deze actie worden genoemd bij gerelateerde
        advertenties. Gegevens voor advertentieproviders We delen uw
        persoonsgegevens niet met externe adverteerders of advertentienetwerken,
        behalve in het geval van: (i) gehashte id’s of apparaatidentificators
        (voor zover ze in sommige landen als persoonsgegevens worden beschouwd);
        (ii) met uw afzonderlijke toestemming (zoals bij formulieren voor het
        genereren van leads); of (iii) gegevens die al zichtbaar zijn voor
        gebruikers van de Services (zoals uw profiel). Als u echter een
        advertentie binnen of buiten onze Services bekijkt of hierop klikt,
        ontvangt de advertentieprovider een signaal dat iemand de pagina met de
        advertentie heeft bezocht. Via het gebruik van mechanismen zoals cookies
        kunnen ze vaststellen dat u dat bent geweest. Advertentiepartners kunnen
        persoonlijke gegevens die door de adverteerder rechtstreeks bij u zijn
        verzameld, koppelen aan uw gehashte id’s of apparaatidentificators die
        van ons zijn ontvangen. In dergelijke gevallen verplichten we deze
        advertentiepartners contractueel om hiervoor eerst uw expliciete
        toestemming te vragen. 3.4. Marketing Naast het adverteren van onze
        Services gebruiken we gegevens van Leden, gebruikers en content voor
        uitnodigingen en berichten waarmee lidmaatschap en netwerkgroei,
        interacties en onze Services worden gepromoot, bijvoorbeeld uw
        connecties laten zien dat u een functie op onze Services hebt gebruikt.
        3.5. Ontwikkeling van Services en onderzoek Serviceontwikkeling We
        gebruiken gegevens, waaronder openbare feedback, om via onderzoek en
        ontwikkeling van onze Services u en anderen een betere en persoonlijkere
        ervaring te kunnen bieden, het aantal leden, gebruikers en de
        betrokkenheid bij onze Services te kunnen vergroten en derden te kunnen
        helpen om contact te leggen en opportuniteiten te vinden. Overig
        onderzoek We proberen andere opportuniteiten te creëren voor Leden en
        gebruikers van over de hele wereld en helpen hen. We gebruiken de
        persoonlijke gegevens waarover we beschikken om diverse trends te
        onderzoeken. In sommige gevallen werken we hiervoor samen met vertrouwde
        derden om dit onderzoek te verrichten onder controlesystemen die zijn
        ontworpen om uw privacy te beschermen. We publiceren inzichten als
        samengevoegde, anonieme gegevens in plaats van persoonlijke gegevens of
        staan anderen toe deze publiceren. Enquêtes Opiniepeilingen en enquêtes
        kunnen door ons en anderen uitgevoerd worden via onze Services. U bent
        niet verplicht om te reageren op opiniepeilingen of enquêtes en u kan
        kiezen welke gegevens u verstrekt. U kan zich afmelden voor
        uitnodigingen voor enquêtes. 3.6. Klantondersteuning We gebruiken
        gegevens (met inbegrip van uw berichten) om klachten en problemen met de
        Services (zoals bugs) te onderzoeken, hierop te reageren en deze op te
        lossen. 3.7. Inzichten die niet tot u kunnen worden herleid We gebruiken
        uw gegevens om inzichten te bekomen en te delen die niet tot u
        persoonlijk kunnen worden herleid. We kunnen bijvoorbeeld uw gegevens
        gebruiken om statistieken over onze Leden te genereren, om bijvoorbeeld
        demografische inzichten te bekomen. 3.8. Beveiliging en onderzoek We
        gebruiken uw gegevens (met inbegrip van uw berichten) voor
        veiligheidsdoeleinden of ter voorkoming van mogelijke fraude of andere
        schendingen van onze gebruikersovereenkomst en/of pogingen om onze
        Leden, Gebruikers of anderen schade te berokkenen. Ook automatisch
        genomen beslissingen, door middel van artificiële intelligentie en/of
        machine learning vallen hieronder. 3.9. Back-up’s van de gegevens worden
        genomen en gedurende 1 maand bijgehouden. Hoe worden deze
        persoonsgegevens gedeeld? 4.1. Onze services Profiel Uw volledige
        profiel is enkel zichtbaar voor Leden waarmee u geconnecteerd bent.
        Afhankelijk van uw instellingen kan het ook zichtbaar zijn voor anderen
        binnen of buiten onze Services (bijvoorbeeld Bezoekers van onze Services
        of gebruikers van zoekmachines van derden). ● Wanneer u een artikel of
        bijdrage (zoals een update, afbeelding, video of artikel) openbaar
        deelt, kan het door iedereen worden bekeken en overal opnieuw worden
        gedeeld (afhankelijk van uw instellingen). Leden, gebruikers, Bezoekers
        en anderen kunnen uw openbaar gedeelde content vinden en zien, met
        inbegrip van uw naam (en foto als u deze hebt verstrekt). ● In een groep
        zijn bijdragen zichtbaar voor andere leden van de groep. Uw lidmaatschap
        van groepen is openbaar en onderdeel van uw profiel, maar u kunt de
        zichtbaarheid wijzigen in uw instellingen. ● Alle gegevens die u deelt
        via pagina’s van andere organisaties op onze Services, zijn zichtbaar
        voor deze organisaties en voor anderen die deze pagina’s bezoeken. ●
        Wanneer u een persoon of organisatie volgt, bent u voor die persoon of
        organisatie en de paginabeheerder zichtbaar als volger. ● We laten het
        afzenders weten wanneer u een interactie aangaat met hun bericht,
        overeenkomstig uw instellingen (indien van toepassing). ● Afhankelijk
        van uw instellingen laten we een Lid of gebruiker weten wanneer u
        zijn/haar profiel bekijkt. ● Wanneer u de content van een ander
        (waaronder advertenties) markeert als interessant, opnieuw deelt of
        becommentarieert, kunnen andere deze ‘sociale acties’ zien en deze met u
        associëren (zoals uw naam, profiel en foto, indien door u verstrekt).
        Daarbuiten worden de persoonsgegevens door IForYou niet gedeeld met
        derden (partners en/of anderen). Archivering van communicatie Sommige
        Leden of gebruikers zijn wettelijk of volgens andere voorschriften
        verplicht om hun berichten en activiteiten op sociale media te
        archiveren. Ze gebruiken services van anderen om hen deze
        archiveringsservices te bieden. Daarom biedt IForYou de mogelijkheid tot
        archivering. Wettelijke openbaarmaking Het is mogelijk dat we gegevens
        over u openbaar moeten maken wanneer we hiertoe worden verplicht door of
        op basis van een wet of reglementering, rechterlijke uitspraak,
        dagvaarding of andere juridische procedure, of als we in goed vertrouwen
        van mening zijn dat openbaarmaking redelijkerwijs noodzakelijk is om (1)
        vermeende of feitelijke onwettelijke activiteiten te onderzoeken, te
        voorkomen en actie hiertegen te ondernemen of om handhavingsinstanties
        van de overheid bij te staan; (2) onze overeenkomsten met u af te
        dwingen, (3) vorderingen of aantijgingen van derden te onderzoeken en
        ons hiertegen te verdedigen, (4) de veiligheid of integriteit van onze
        Service te beschermen (door bijvoorbeeld de gegevens te delen met
        bedrijven die met soortgelijke bedreigingen worden geconfronteerd); of
        (5) de rechten en veiligheid van IForYou, onze Leden, medewerkers of
        anderen uit te oefenen resp. te beschermen. We proberen Leden op de
        hoogte te stellen van wettelijke verzoeken om hun persoonlijke gegevens
        wanneer dit naar ons oordeel gepast is, tenzij dit wettelijk of volgens
        een gerechtelijke uitspraak verboden is of wanneer het verzoek een
        noodgeval is. We kunnen dergelijke verzoeken betwisten wanneer we de
        verzoeken te breed of te vaag achten of wanneer de passende bevoegdheid
        ontbreekt, maar we zeggen niet toe elk verzoek te betwisten. Zie onze
        richtlijnen voor gegevensverzoeken en ons transparantierapport voor meer
        informatie. Uw rechten en plichten Gegevensopslag We bewaren uw
        persoonlijke gegevens voor de duur van het bestaan van uw account of
        zolang het nodig is om u onze Services te kunnen bieden. Het gaat
        hierbij om de gegevens die door u en anderen aan ons verstrekt zijn en
        gegevens afgeleid uit uw gebruik van onze Services. Na verwijdering of
        stopzetting van uw account bewaren we uw gegevens in geanonimiseerde
        vorm. Toegang tot en controle over uw persoonsgegevens Op eenvoudig
        verzoek kan u inzage krijgen in de persoonsgegevens die wij van u
        verwerken. Wat betreft het verzamelen, gebruiken en delen van uw
        gegevens heeft u verschillende keuzemogelijkheden. U kan bepaalde
        gegevens verwijderen, corrigeren, de zichtbaarheid ervan beheren. Het
        beheer van de door uzelf ingegeven persoonsgegevens verloopt via uw
        instellingen. Meer in het bijzonder kan u: - Gegevens verwijderen: u kan
        ons vragen al uw persoonlijke gegevens of bepaalde gegevens te
        verwijderen (bijv. wanneer deze niet meer nodig zijn om u Services te
        bieden). - Gegevens wijzigen of corrigeren: u kunt sommige van uw
        persoonlijke gegevens wijzigen via uw account. Ook u kan ons in bepaalde
        gevallen (als er iets onjuist is) vragen om uw gegevens te wijzigen, bij
        te werken of te herstellen. - Bezwaar maken, of het gebruik van gegevens
        beperken of verbieden: u kan ons vragen om het gebruik van alle of
        bepaalde persoonlijke gegevens te stoppen (zoals wanneer we geen
        juridisch recht hebben om deze te gebruiken) of het gebruik te beperken
        (wanneer uw persoonlijke gegevens onnauwkeurig zijn of onrechtmatig
        worden bewaard). - Recht op toegang en/of meenemen van uw gegevens: u
        kan ons vragen om een kopie van uw persoonlijke gegevens en een kopie
        van uw persoonlijke gegevens die machinaal leesbaar is. U kan contact
        met ons opnemen via legal@iforyou.com , zodat we uw verzoek kunnen
        overwegen in overeenstemming met de van toepassing zijnde wetgeving. U
        hebt het recht om contact op te nemen met, of klachten in te dienen bij
        de Gegevensbeschermingsautoriteit
        (https://www.gegevensbeschermingsautoriteit.be/burger/acties/contact ).
        Regio’s en landen buiten België beschikken mogelijk over aanvullende
        rechten onder hun lokale wetgeving. Gelieve er bij het ter beschikking
        stellen van je gegevens mee rekening te houden dat we de wetgeving van
        België toepassen. Verwijdering van uw account Als u ervoor kiest om uw
        IForYou-account stop te zetten, zijn uw persoonlijke gegevens binnen
        redelijke termijn niet meer zichtbaar voor anderen op onze Services. We
        verwijderen gegevens van een gesloten account in het algemeen binnen 30
        dagen nadat de account is gesloten, behalve in de gevallen hieronder
        beschreven. We bewaren uw persoonlijke gegevens zelfs nadat u uw account
        hebt gesloten als dat redelijkerwijs nodig is om aan onze wettelijke
        verplichtingen te voldoen (waaronder wethandhavingsverzoeken),
        voorschriften na te leven, geschillen op te lossen, de beveiliging te
        handhaven, fraude en misbruik te voorkomen (indien we bijvoorbeeld uw
        account hebben beperkt wegens schending van onze Beleidslijnen voor de
        community) of uw verzoek tot uitschrijving voor verdere berichten van
        ons uit te voeren. We bewaren de geanonimiseerde gegevens nadat uw
        account is gesloten. Gegevens die u met anderen hebt gedeeld
        (bijvoorbeeld via interne chat, updates of groepsbijdragen) blijven
        zichtbaar nadat u uw account hebt gesloten of de gegevens hebt
        verwijderd uit uw eigen profiel of postvak IN. We hebben geen controle
        over gegevens die andere Leden of gebruikers buiten onze Services hebben
        gekopieerd. Content in de groepen, beoordelingen en recensies die worden
        geassocieerd met gesloten accounts, worden weergegeven met een onbekende
        gebruiker als bron. Uw profiel wordt mogelijk nog in de services van
        anderen (zoals resultaten van zoekmachines) weergegeven totdat ze hun
        cache hebben vernieuwd. Overige belangrijke informatie Beveiliging We
        implementeren veiligheidsmaatregelen die zijn ontworpen om uw gegevens
        te beschermen, zoals HTTPS. We controleren onze systemen regelmatig op
        mogelijke veiligheidsrisico’s en aanvallen. We kunnen de veiligheid van
        door u verzonden gegevens echter niet garanderen. Er bestaat geen
        garantie dat niemand zich toegang verschaft tot gegevens en deze
        openbaar maakt, wijzigt of vernietigt door een schending van onze
        fysieke, technische of bestuurlijke veiligheidsmaatregelen. Indien
        ondanks de beveiligingsmaatregelen toch een datalek zou ontstaan,
        waarvan uw persoonsgegevens deel uitmaken, zullen wij dit registreren en
        het datalek melden aan de toezichthouder, tenzij het onwaarschijnlijk is
        dat het datalek risico’s oplevert voor de privacy van de betrokken
        personen. Bovendien zullen wij u op de hoogte brengen wanneer het
        datalek waarschijnlijk een groot risico voor u oplevert. Juridische
        grondslagen We zullen persoonlijke gegevens alleen verzamelen en
        verwerken waar er hiervoor juridische grondslagen bestaan. Juridische
        grondslagen bestaan uit onder andere toestemming. Waar we vertrouwen op
        uw toestemming om persoonlijke gegevens te verwerken, hebt u het recht
        om uw toestemming op elk gewenst moment in te trekken of te weigeren.
        Als u vragen hebt over de juridische grondslagen waarop we uw
        persoonlijke gegevens verzamelen en gebruiken, kunt u hier contact
        opnemen met onze Data Protection Officer via het mailadres
        legal@iforyou.com. De Data Protection Officer is Johan Rombouts. Contact
        Als u vragen of klachten heeft over dit Beleid, kunt u via
        legal@iforyou.com contact opnemen met IForYou.com.
      </div>
    </div>
  </div>
</div>
