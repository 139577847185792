import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigationService';
import { NavObject } from 'src/app/models/navObject';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements OnInit {
  @Input() page: string = 'videos';
  public item: NavObject = new NavObject(0, "", "", "");
  private navItems: NavObject[] = [];

  constructor(private navService: NavigationService) { }

  private initNav(): void {
    this.navItems = this.navService.getNavItemsSmall();
  }

  private setIcon(): void {
    this.item = this.navItems.filter(item => item.url == this.page)[0];
  }

  ngOnChanges(): void {
    this.setIcon();
  }
  ngOnInit(): void {
    this.initNav();
    this.setIcon();
  }

}
