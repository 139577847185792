import { Component, HostListener, OnInit } from '@angular/core';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { NavigationService } from '../core/services/navigationService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})
export class VisionComponent implements OnInit {
  public media: MediaObject[] = [];

  constructor(private mediaService: MediaService, private event: EventService, private nav: NavigationService) { }

  // listen for scroll event 
  @HostListener("mouseover")
  public callNav() {
    this.nav.setActiveNavItem(2);
  }

  private initMedia(): void {
    this.media = this.mediaService.getMediaVisionPage();
  }

  public setMediaPhone(media: MediaObject): void {
    this.event.callVisionPhoneEvent(media);
  }

  ngOnInit(): void {
    this.initMedia();
  }

}
