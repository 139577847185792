import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePageSmallRoutingModule } from './home-page-small-routing.module';
import { HomePageSmallComponent } from './home-page-small.component';
import { CoreModule } from '../core/core.module';
import { SharedSmallModule } from '../shared-small/shared-small.module';


@NgModule({
  declarations: [
    HomePageSmallComponent
  ],
  imports: [
    CommonModule,
    HomePageSmallRoutingModule,
    CoreModule, SharedSmallModule
  ],
  exports: [
    HomePageSmallComponent
  ]
})
export class HomePageSmallModule { }
