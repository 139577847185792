import { Component, OnInit } from '@angular/core';
import { EventService } from '../core/services/eventService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {
  public test: MediaObject = new MediaObject(0, "", "", "test image", "", "", "", "https://www.fillmurray.com/640/360", "https://www.fillmurray.com/640/360", "", "");

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    /*testing*/
    const mediaObject = new MediaObject(1, "", "", "", "", "", "", "", "", "", "");
  }

  public callFade(): void {
    this.eventService.callTitleFadeEvent(true);
  }

}
