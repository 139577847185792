import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-testimonials-content',
  templateUrl: './testimonials-content.component.html',
  styleUrls: ['./testimonials-content.component.scss']
})
export class TestimonialsContentComponent implements OnInit {
  private mediaEvent: Subscription;
  public playing: boolean = false;
  public media: MediaObject = new MediaObject(0, "", "", "", "", "", "", "", "", "", "");

  constructor(private event: EventService, private mediaService: MediaService) {
    this.mediaEvent = this.event.getTestimonialPhoneEvent().subscribe((media) => {
      this.media = media;
      this.playing = false;
    })
  }

  private initMedia(): void {
    this.media = this.mediaService.getMediaTestimonialPage()[0];
  }
  ngOnInit(): void {
    this.initMedia();
  }

}
