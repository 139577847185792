import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VideosSmallRoutingModule } from './videos-small-routing.module';
import { VideosSmallComponent } from './videos-small.component';
import { SharedSmallModule } from '../shared-small/shared-small.module';
import { CoreModule } from '../core/core.module';


@NgModule({
  declarations: [
    VideosSmallComponent
  ],
  imports: [
    CommonModule,
    VideosSmallRoutingModule,
    CoreModule,
    SharedSmallModule
  ],
  exports: [
    VideosSmallComponent
  ]
})
export class VideosSmallModule { }
