import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  @Input() error: any;
  constructor(private location: Location) {}

  ngOnInit(): void {}

  public goBack() {
    this.location.back();
  }
}
