<app-page-navigation [page]="'social'" [media]="mediaNav">
  <div class="touch-container d-flex align-items-center flex-wrap mb-5">
    <div class="d-flex flex-wrap">
      <h1 class="fg-accent1 touch-title text-upper w-100">Get in touch</h1>
      <div class="description text-justify mt-2">
        We have a dream, and if you’re on this page, we think you do too.
        Download WeDream and start sharing today. Got feedback, a question or a
        genius idea to make WeDream even better? Awesome. That's exactly what we
        need at this stage of our project. And let's be honest: we'll need it
        afterwards too. Without you there is no WeDream!
      </div>
      <div class="touch-form mt-2">
        <!-- <app-get-in-touch-form></app-get-in-touch-form> -->
      </div>
    </div>
  </div>
</app-page-navigation>
