import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { NavigationService } from '../core/services/navigationService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-tester',
  templateUrl: './tester.component.html',
  styleUrls: ['./tester.component.scss'],
})
export class TesterComponent implements OnInit {
  @Input() media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );

  constructor(
    private mediaService: MediaService,
    private nav: NavigationService
  ) {}

  @HostListener('mouseover')
  public callNav() {
    this.nav.setActiveNavItem(100);
  }

  ngOnInit(): void {}
}
