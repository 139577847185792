<a href="/#tester" (click)="navigateTester()"           aria-label="Download"
>
  <div
    class="sign-up-button border border-accent1 fg-color1 d-flex justify-content-center align-items-center text-upper"
    [ngClass]="{ 'bg-gradient5': active, 'fgi-color2': active }"
  >
    d<br />o<br />w<br />n <br />l<br />
    o<br />a<br />d
  </div></a
>
