import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TesterSmallRoutingModule } from './tester-small-routing.module';
import { TesterSmallComponent } from './tester-small.component';
import { TesterModule } from '../tester/tester.module';
import { CoreModule } from '../core/core.module';
import { SharedSmallModule } from '../shared-small/shared-small.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TesterSmallComponent],
  imports: [
    CommonModule,
    TesterSmallRoutingModule,
    TesterModule,
    SharedSmallModule,
    SharedModule,
    CoreModule,
  ],
  exports: [TesterSmallComponent],
})
export class TesterSmallModule {}
