import { Component, Input, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-video-tiles',
  templateUrl: './video-tiles.component.html',
  styleUrls: ['./video-tiles.component.scss']
})
export class VideoTilesComponent implements OnInit {
  public homeMedia: MediaObject[] = [];
  public videosMedia: MediaObject[] = [];
  @Input() showFade: boolean = false;

  constructor(private media: MediaService, private event: EventService) { }

  public setMediaPhone(index: number): void {
    if (this.showFade) {
      const media = this.videosMedia[index];
      this.event.callVideosPhoneEvent(media);
    } else {
      const media = this.homeMedia[index];
      this.event.callHomePagePhoneEvent(media);
    }
  }

  private setHomeMedia(): void {
    this.homeMedia = this.media.getMediaHome();
  }

  private setVideosMedia(): void {
    this.videosMedia = this.media.getmediaVideoPage();
  }

  ngOnInit(): void {
    this.setHomeMedia();
    this.setVideosMedia();
  }
}
