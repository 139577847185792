import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteSmallRoutingModule } from './site-small-routing.module';
import { SiteSmallComponent } from './site-small.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { HomePageSmallModule } from '../home-page-small/home-page-small.module';
import { SharedSmallModule } from '../shared-small/shared-small.module';
import { VideosSmallModule } from '../videos-small/videos-small.module';
import { TestimonialsSmallModule } from '../testimonials-small/testimonials-small.module';
import { TesterSmallModule } from '../tester-small/tester-small.module';
import { VisionSmallModule } from '../vision-small/vision-small.module';
import { PhasesSmallModule } from '../phases-small/phases-small.module';
import { GetInTouchSmallModule } from '../get-in-touch-small/get-in-touch-small.module';
import { HomePageMediumModule } from '../home-page-medium/home-page-medium.module';

@NgModule({
  declarations: [SiteSmallComponent],
  imports: [
    CommonModule,
    SiteSmallRoutingModule,
    SharedSmallModule,
    CoreModule,
    HomePageSmallModule,
    VideosSmallModule,
    TestimonialsSmallModule,
    TesterSmallModule,
    VisionSmallModule,
    PhasesSmallModule,
    GetInTouchSmallModule,
    HomePageMediumModule,
    SharedModule,
  ],
  exports: [SiteSmallComponent],
})
export class SiteSmallModule {}
