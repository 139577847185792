import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  titleEvent: Subscription;
  fade: boolean = false;

  constructor(private eventService: EventService) {
    this.titleEvent = this.eventService.getTitleFadeEvent().subscribe((state) => {
      this.fadeTitle(state);
    })
  }

  private fadeTitle(state: boolean): void {
    this.fade = state;
  }

  ngOnInit(): void {
  }



}
