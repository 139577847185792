import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetInTouchSmallRoutingModule } from './get-in-touch-small-routing.module';
import { GetInTouchSmallComponent } from './get-in-touch-small.component';
import { GetInTouchModule } from '../get-in-touch/get-in-touch.module';
import { SharedSmallModule } from '../shared-small/shared-small.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [GetInTouchSmallComponent],
  imports: [
    CommonModule,
    GetInTouchSmallRoutingModule,
    GetInTouchModule,
    CoreModule,
    SharedSmallModule,
    SharedModule,
  ],
  exports: [GetInTouchSmallComponent],
})
export class GetInTouchSmallModule {}
