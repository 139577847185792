<div class="d-flex flex-wrap w-100 container-page">
  <div class="d-flex  justify-content-center flex-wrap side-circles">
    <!-- navigation  -->
   <div class="header" *ngIf="page != ''">
    <app-header-navigation [page]="page"></app-header-navigation>
   </div>
    <div *ngIf="media.length > 0" class="d-flex flex-grow-1 body" [ngClass]="{'align-items-center' : page != 'social','bg-transparent' : page == 'social', 'space-social' : page == 'social' }">
      <app-circles-navigation [media]="media" [page]="page"></app-circles-navigation>
    </div>
   
  </div>
  <!-- content  -->
  <div class="content">
      <ng-content></ng-content>
  </div>
</div>