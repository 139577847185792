import { Component, OnInit } from '@angular/core';
import { MediaService } from '../core/services/mediaService';
import { FillerObject } from '../models/fillerObject';

@Component({
  selector: 'app-site-small',
  templateUrl: './site-small.component.html',
  styleUrls: ['./site-small.component.scss'],
})
export class SiteSmallComponent implements OnInit {
  public testerFiller: FillerObject = new FillerObject(
    0,
    '',
    '',
    '',
    '',
    '',
    ''
  );
  public homeFiller: FillerObject = new FillerObject(0, '', '', '', '', '', '');
  public testerText: any[] = [];

  constructor(private mediaService: MediaService) {}

  private initFillers(): void {
    this.homeFiller = this.mediaService.getHomeFiller();
    this.testerFiller = this.mediaService.getTesterFiller();
    this.testerText = this.mediaService.getMediaTesterPage();
  }

  public calcHeight(): number {
    let total = document.querySelector('.content-size')!.clientHeight;
    /* 180 is compenastion for move up
     * top: -80
     * 100px bar beneath homepage
     */
    return total + 180;
  }

  /*set the height of the navigation linde*/
  public setSideNavigationSize() {
    const size = this.calcHeight() + 'px';
    (
      document.querySelector('.side-navigation') as HTMLDivElement
    ).style.setProperty('--size', size);
  }

  ngAfterViewChecked(): void {
    this.setSideNavigationSize();
  }
  ngOnInit(): void {
    this.initFillers();
  }
}
