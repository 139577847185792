import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { NavigationService } from 'src/app/core/services/navigationService';
import { NavObject } from 'src/app/models/navObject';
@Component({
  selector: 'app-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.scss'],
})
export class NavItemsComponent implements OnInit {
  public navItems: NavObject[] = [];
  private activeNavItem: Subscription;
  public activeId: number = 0;
  private prevId: number = 0;
  public maxId: number = 0;
  private navInit: boolean = false;

  constructor(
    private navService: NavigationService,
    private eventService: EventService
  ) {
    this.activeNavItem = this.navService
      .getActiveNavItem()
      .subscribe((active) => {
        this.prevId = this.activeId;
        this.activeId = active;
        this.setNavbar(active);
      });
  }

  private initNav(): void {
    this.navItems = this.navService.getNavItems();
    this.maxId = this.navItems.length - 1;
  }

  public activate(item: NavObject) {
    this.navService.setActiveNavItem(item.id);
    if (item.id != 0) {
      this.enableScroll();
    }
  }

  public enableScroll(): void {
    this.eventService.callscrollEvent(false);
  }

  public callNavbar(state: boolean): void {
    this.eventService.callTitleFadeEvent(state);
  }

  public setNavbar(active: number) {
    if (active > 0 && !this.navInit) {
      this.callNavbar(true);
    }
    if (active == 0 && this.prevId != 0) {
      this.navService.setResetHomeEvent(true);

      this.navService.setEnableScrollEvent(true);
    }
  }
  ngOnInit(): void {
    this.initNav();
  }
}
