import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { ProgressType } from 'src/app/models/enums/progressType';
import { MediaObject } from 'src/app/models/mediaObject';
import { ProgressObject } from 'src/app/models/progressObject';

@Component({
  selector: 'app-phases-content-small',
  templateUrl: './phases-content-small.component.html',
  styleUrls: ['./phases-content-small.component.scss'],
})
export class PhasesContentSmallComponent implements OnInit {
  @Input() media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  private rawProgress: ProgressObject[] = [];
  public progress: ProgressObject[] = [];
  public progressType: typeof ProgressType = ProgressType;

  constructor(private mediaService: MediaService) {}

  private initMedia(): void {
    this.rawProgress = this.mediaService.getProgressPhasesPage();
  }

  private filterProgress(): void {
    this.progress = this.rawProgress.filter((p) => p.mediaId == this.media.id);
  }

  public calculateScore(score: number): string {
    const score2 = 200 - (200 * score) / 100;
    return `stroke-dashoffset:${score2}px!important`;
  }

  public initProgress(): void {
    this.rawProgress = this.mediaService.getProgressPhasesPage();
    this.filterProgress();
  }

  ngOnChanges(): void {
    this.filterProgress();
  }

  ngOnInit(): void {
    this.initMedia();
    this.initProgress();
  }
}
