<div
  class="phone-home"
  [ngClass]="{
    'phone-home-animation': animationDown,
    'phone-home-animation-reversed': animationUp
  }"
>
  <!-- phone container -->
  <app-phone>
    <div
      class="w-100 h-100 bg-color2 position-relative"
      [ngClass]="{ 'phone-content-animation': animationDown }"
    >
      <ng-template
        [ngIf]="!(animationDown || animationUp || indexPage)"
        [ngIfElse]="mediaPresent"
      >
        <div
          class="d-flex flex-wrap justify-content-center align-items-center h-100"
        >
          <div class="d-flex justify-content-center flex-wrap">
            <!-- logo  -->
            <!-- <div class="logo-image logo-img"></div> -->
            <!-- loading animation  -->
            <div class="fade loading" [ngClass]="{ 'd-none': !isLoading }">
              <app-loading></app-loading>
            </div>

            <!-- pop animation -->
            <div [ngClass]="{ pop: !isLoading }">
              <div class="we fg-color1">We</div>
              <div class="connect fg-accent1 my-3">Connect</div>
              <div class="dreams fg-color1">Dreams</div>
            </div>
          </div>
        </div>
        <div class="logo-container">
          <div class="logo-bottom">
            <app-logo></app-logo>
          </div>
        </div>
      </ng-template>
      <ng-template #mediaPresent>
        <ng-template
          [ngIf]="indexPage || animationUp"
          [ngIfElse]="animationDown"
        >
          <img [src]="homeMedia.frameUrl" alt="WeDream rules" class="phone-img" loading="lazy" />
        </ng-template>
        <ng-template #animationDown>
          <ng-template [ngIf]="playing" [ngIfElse]="notPlaying">
            <video
              [src]="videosMedia.videoUrl"
              autoplay
              type="video/mp4"
              controls
            >
              <!-- <source> -->
            </video>
          </ng-template>
          <ng-template #notPlaying>
            <img [src]="videosMedia.gifUrl" alt="WeDream rules" loading="lazy" />
            <span
              class="material-icons position-absolute play"
              (click)="startPlaying()"
            >
              play_circle_filled
            </span>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
  </app-phone>
</div>
