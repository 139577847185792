import { Component, Input, OnInit } from '@angular/core';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-image-tile',
  templateUrl: './image-tile.component.html',
  styleUrls: ['./image-tile.component.scss']
})
export class ImageTileComponent implements OnInit {

  @Input() media: MediaObject = new MediaObject(0, "", "", "test image", "", "", "", "https://picsum.photos/200/300", "", "", "");
  @Input() mediaFade?: MediaObject;
  @Input() title: string = "hallo";
  @Input() subTitle: string = "27/05/2008";
  @Input() number: number = 0;
  @Input() type: string = "";
  @Input() showFade = false;

  constructor() { }

  ngOnInit(): void {
    //console.log(this.mediaFade)
  }

}
