import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-video-tiles-testimonials',
  templateUrl: './video-tiles-testimonials.component.html',
  styleUrls: ['./video-tiles-testimonials.component.scss']
})
export class VideoTilesTestimonialsComponent implements OnInit {
  public mediaList: MediaObject[] = [];
  public media = new MediaObject(0, "", "", "", "", "", "", "", "", "", "");

  constructor(private eventService: EventService, private mediaService: MediaService) { }

  public setMediaPhone(media: MediaObject): void {
    this.media = media;
    this.eventService.callTestimonialPhoneEvent(media);
  }

  private initMedia(): void {
    this.mediaList = this.mediaService.getMediaTestimonialPage();

  }

  ngOnInit(): void {
    this.initMedia();
  }

}
