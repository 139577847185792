import { Component, Input, OnInit } from '@angular/core';
import { FillerObject } from 'src/app/models/fillerObject';

@Component({
  selector: 'app-filler',
  templateUrl: './filler.component.html',
  styleUrls: ['./filler.component.scss']
})
export class FillerComponent implements OnInit {
  @Input() fillerContent: FillerObject = new FillerObject(0, "for", "young", "poeple", "by", "young", "poeple",);

  constructor() { }

  ngOnInit(): void {
  }

}
