<div class="tile-container">
  <div
    class="d-flex flex-wrap align-items-space-between h-100 flex-column justify-content-space-between"
  >
    <div
      *ngFor="let item of mediaList; let i = index"
      class="media col-20 text-left fg-accent1"
      (click)="setMediaPhone(item)"
    >
      <app-image-tile
        [media]="item"
        [type]="'title'"
        [title]="item.name"
        [subTitle]="item.subName"
        [number]="i + 1"
      ></app-image-tile>
    </div>
  </div>
</div>
