import {
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from '../core/services/eventService';
import { MediaService } from '../core/services/mediaService';
import { NavigationService } from '../core/services/navigationService';
import { MediaObject } from '../models/mediaObject';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  /*event triggered => true*/
  public animationTriggerd: boolean = false;
  public animationReversed: boolean = false;

  /*Amount of time that scroll and click are disabled*/
  private timeOut: number = 3500;
  /*Boolean for when site loading*/
  public isLoading: boolean = true;
  /*media object for videos page*/
  public media: MediaObject = new MediaObject(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  );
  /*tile click event*/
  mediaEvent: Subscription;
  /*duration of animation*/
  private animationTiming: number = 2000;
  /*disable animation trigger while animation is playing*/
  private animationDisabled: boolean = false;

  private scrollForAnimation: boolean = true;

  constructor(
    private event: EventService,
    private mediaService: MediaService,
    private nav: NavigationService
  ) {
    this.mediaEvent = this.event.getVideosPhoneEvent().subscribe((media) => {
      this.media = media;
    });

    this.nav.getResetHomeEvent().subscribe((state) => {
      this.resetHome();
    });
  }

  // listen for scroll event
  @HostListener('wheel', ['$event'])
  public onScroll(event: any) {
    this.scrollForAnimation && event.preventDefault();
    /*call nav*/
    this.nav.setActiveNavItem(0);
    //adjust sensitivity of scroll
    if (Math.abs(event.offsetY) > 10) {
      !this.isLoading &&
        !this.animationDisabled &&
        this.triggerAnimation(event.wheelDelta);
    }
  }

  /*disable scroll and clicks during load animation*/
  private disableEvents(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, this.timeOut);
  }

  /*determine scroll direction
   * true: scroll top
   * false: scroll bottom
   */
  private scrollDirection(delta: number): boolean {
    return delta > 0;
  }

  /*enforce scroll first time to bottom*/
  public triggerAnimation(delta: number) {
    if (!this.animationTriggerd && !this.animationReversed) {
      this.triggerAnimationDown();
    } else {
      if (this.scrollDirection(delta) && !this.animationReversed) {
        /*to top*/
        this.triggerAnimationUp();
      }
      if (!this.scrollDirection(delta) && !this.animationTriggerd) {
        /*to bottom*/
        this.triggerAnimationDown();
      }
    }
  }

  /*trigger animation down*/
  public triggerAnimationDown(): void {
    this.disableAnimationTrigger();
    this.animationTriggerd = true;
    this.animationReversed = false;
    this.callFadeNavbar();
    this.initVideosPageVideo();
    /*enbable scroll on page*/
    setTimeout(() => {
      this.scrollForAnimation = false;
    }, this.animationTiming);
  }

  /*trigger animation up*/
  public triggerAnimationUp(): void {
    if (!this.isElementInViewport()) return;
    this.scrollForAnimation = true;
    this.disableAnimationTrigger();
    this.animationTriggerd = false;
    this.animationReversed = true;
    this.reverseFadeNavbar();
    this.initHomeVideo();
  }

  /* get trigger animation from playbutton
   * play button: always event downwards
   */
  public animationTrigger(): void {
    this.event.callPlayVideoEvent(true);
    this.triggerAnimationDown();
  }

  /*trigger navbar animation*/
  public callFadeNavbar(): void {
    this.event.callTitleFadeEvent(true);
  }

  /*trigger navbar animation reverse*/
  public reverseFadeNavbar(): void {
    this.event.callTitleFadeEvent(false);
  }

  /*set video page media
   * default on the first video
   */
  private initVideosPageVideo(): void {
    this.media = this.mediaService.getVideoHome();
    this.event.callVideosPhoneEvent(this.media);
  }

  /*set home media
   * default on the first video
   */
  private initHomeVideo(): void {
    this.media = this.mediaService.getMediaHome()[0];
    this.event.callHomePagePhoneEvent(this.media);
  }

  /* disable animation trigger while animation aka ani-spam*/
  private disableAnimationTrigger(): void {
    this.animationDisabled = true;
    setTimeout(() => {
      this.animationDisabled = false;
    }, this.animationTiming);
  }

  public testerNavigation(): void {
    /*call nav*/
    this.nav.setActiveNavItem(2);
  }

  /*reset home page*/
  private resetHome(): void {
    this.scrollForAnimation = true;
    this.disableAnimationTrigger();
    this.animationTriggerd = false;
    this.animationReversed = true;
    this.event.callTitleFadeEvent(false);
  }

  private isElementInViewport(): boolean {
    var rect = document.getElementById('home')?.getBoundingClientRect();
    if (!rect) return false;
    return rect.top == 0;
  }

  /*on load disable events*/
  ngOnInit(): void {
    this.disableEvents();
  }
}
