<div
  class="privacy-container"
  oncopy="return false"
  oncut="return false"
  onpaste="return false"
>
  <div class="privacy-content">
    <h1 class="text-upper title">Privacy</h1>
    <h2>Terms and conditions</h2>
    <div class="my-5 fg-color6">
      <h3 class="mb-3 fg-color1">
        ALGEMENE VOORWAARDEN VAN TOEPASSING VOOR IEDERE GEBRUIKER
      </h3>
      <div>
        Artikel 1 - Toepasselijkheid 1.1 Algemene Voorwaarden I For You is een
        platform van de onderneming Katz N.V., te Handelslei 193, 2980 Zoersel.
        Deze algemene voorwaarden zijn van toepassing op elke dienstverlening
        van I For You aan de Gebruiker en maken bijgevolg integraal deel uit van
        de contractuele relatie tussen I For You en de Gebruiker. Deze algemene
        voorwaarden zijn, als kaderovereenkomst, niet alleen van toepassing op
        de initiële opdracht van de Gebruiker aan I For You, maar ook op alle
        gebeurlijke navolgende opdrachten, tenzij voor een welbepaalde opdracht
        schriftelijk andere afspraken gemaakt zouden zijn. 1.2
        Gebruiksvoorwaarden Ondernemingen Voor bepaalde diensten voor
        Ondernemingen op het I For You-platform gelden andere voorwaarden. Deze
        Gebruiksvoorwaarden voor Ondernemingen zijn aanvullend op de Algemene
        Voorwaarden. De overige bedingen blijven onverkort van toepassing. 1.3
        Inleidende overwegingen en definities 1.3.1 Definities „I For You”: I
        For You is het platform van Katz N.V. De missie van IFY is om mensen
        samen te brengen en om relaties op te bouwen en te onderhouden via
        dromen. „Account”: Het persoonlijk profiel van een Gebruiker die zich
        geregistreerd heeft om gebruik te maken van het I For You-platform.
        „Gebruiker”: De overkoepelende term voor eenieder die, in welke zin dan
        ook, gebruik maakt van het I For You-platform. „Onderneming”: De
        betalende Accounthouder(s) van het I For You-platform die toegang
        krijgen tot welbepaalde diensten. „Bezoekers”: Het individu dat het I
        For You-platform betreedt en hierdoor toegang krijgt tot een beperkt
        aantal diensten. „Services”: De diensten die I For You aanbiedt aan
        Gebruikers via het I For You-platform. 1.3.2. De totstandkoming van een
        juridisch bindende overeenkomst Indien u zich registreert, toegang
        verschaft of gebruik maakt van onze Services, stemt u ermee in dat u een
        juridisch bindende overeenkomst aangaat met I For You (zelfs indien dit
        gebeurt namens een Onderneming). U gaat tevens akkoord met onze
        Privacyverklaring (zie art.9) en, indien toepasselijk, onze
        Gebruiksvoorwaarden Ondernemingen (zie onderaan). 1.3.3 Services Deze
        Gebruiksovereenkomst is van toepassing op IForYou.com en andere I For
        You-websites, apps, berichten en andere Services waarin wordt aangegeven
        dat ze worden aangeboden onder deze Gebruiksovereenkomst, inclusief
        gegevensverzameling voor deze Services buiten de applicatie. 1.3.4
        Partijen en toepassingsgebied De Gebruiker (waarnaar ook met ’u’ wordt
        verwezen) gaat deze Gebruiksovereenkomst aan met I For You (waarnaar ook
        met ‘we’ en ‘ons’ wordt verwezen). Een Gebruiker is iedere persoon of
        entiteit die toegang heeft tot de I For You-Services. ’Ondernemingen’
        hebben toegang tot alle (betalende) diensten van het I For You-platform.
        Ondernemingen worden onderscheiden van ’Bezoekers’ die slechts toegang
        hebben tot een beperkt aantal diensten. Artikel 2 - Verplichtingen 2.1
        Naleving van de servicevoorwaarden Voor het gebruik van de Services
        stemt u ermee in dat: (1) u niet jonger bent dan de ‘Minimumleeftijd’
        (zie de beschrijving hieronder); (2) u slechts één I For You-account
        hebt dat op uw echte naam staat; en (3) I For You uw gebruik van de
        Services niet al eerder heeft beperkt. Het is in strijd met onze
        voorwaarden om een account met valse gegevens te maken, met inbegrip van
        accounts die namens anderen of personen jonger dan de Minimumleeftijd
        zijn geregistreerd. ‘Minimumleeftijd’ betekent 18 jaar oud. Indien u
        volgens de wet ouder moet zijn voordat I For You wettig de Services aan
        u mag leveren (waaronder de verzameling, de opslag en het gebruik van uw
        gegevens), is de Minimumleeftijd gelijk aan deze hogere leeftijd. 2.2
        Bescherming en beheer van uw account Gebruikers zijn Accounthouders. U
        stemt in met het volgende: (1) u gebruikt een sterk wachtwoord dat
        strikt persoonlijk is, (2) u draagt geen enkel deel van uw account over
        aan anderen en (3) u houdt zich aan de wet en de onderstaande
        gedragsregels. U bent verantwoordelijk voor alles wat er gebeurt via uw
        account, tenzij u deze verwijdert of misbruik meldt. Elke inbreuk op het
        vertrouwelijk karakter moet gemeld worden aan I For You, waarna de
        nodige stappen ondernomen zullen worden om de vertrouwelijkheid van het
        account te herstellen. 2.3 Kennisgeving en berichten U stemt ermee in
        dat we u op de volgende manieren kennisgevingen en berichten kunnen doen
        toekomen: (1) binnen de Service of (2) via de door u opgegeven
        contactgegevens (zoals bijvoorbeeld een e-mailadres, mobiel nummer of
        postadres). U houdt uw contactgegevens up-to-date. 2.4 Delen van
        informatie Via onze Services kunt u op allerlei manieren berichten
        versturen en gegevens delen, waaronder uw dromen. Door u gedeelde of
        geplaatste gegevens en content zijn mogelijk zichtbaar voor andere
        gebruikers of anderen (ook buiten de Services). Indien we instellingen
        beschikbaar hebben gemaakt, respecteren we de keuzes over wie content of
        informatie mag bekijken (bijvoorbeeld berichtinhoud voor uw
        geadresseerden, content die alleen met I For You-connecties wordt
        gedeeld, …). Artikel 3 - Rechten en beperkingen 3.1 Licentie In de
        relatie tussen de Gebruiker en I For You bent u eigenaar van de content
        en gegevens die u indient of op de Services plaatst en u verleent alleen
        I For You de volgende niet-exclusieve licentie: Een wereldwijd,
        niet-overdraagbaar en niet in sublicentie te verlenen recht om de door u
        via onze Services en de services van anderen verstrekte gegevens te
        gebruiken, te kopiëren, te wijzigen, te distribueren, te publiceren en
        te verwerken, zonder verdere toestemming, kennisgeving en/of compensatie
        voor u of anderen. Deze rechten worden op de volgende manieren beperkt:
        - U kunt deze licentie voor specifieke content beëindigen door deze
        content van de Services te verwijderen, behalve (a) voor zover u de
        content hebt gedeeld met anderen als onderdeel van de Service en deze
        personen de content hebben gekopieerd, opnieuw hebben gedeeld of hebben
        opgeslagen, en (b) voor de redelijke tijd dat het duurt om de content te
        verwijderen uit back-up- en andere systemen; - Uw gegevens zijn anoniem,
        waardoor we uw content niet opnemen in advertenties voor de producten en
        services van derden aan anderen zonder uw toestemming. We hebben echter
        het recht, zonder betaling aan u of anderen, om advertenties in de
        nabijheid van uw content en gegevens te tonen, en uw sociale acties
        zoals markeren als interessant, becommentariëren, volgen, delen kunnen
        zichtbaar zijn en worden opgenomen in advertenties, zoals uiteengezet in
        het Privacybeleid. Indien u een functie van de Service gebruikt, kunnen
        we dit vermelden met uw naam en foto om die functie binnen onze Services
        te promoten, afhankelijk van uw instellingen; - Uw toestemming is
        vereist als we anderen het recht willen geven om uw content buiten de
        Services te gebruiken. Als u er echter voor kiest om uw bijdrage te
        plaatsen als ‘openbaar’, schakelen we een functie in waarmee andere
        Gebruikers die openbare bijdrage op services van derden kunnen plaatsen
        en kunnen we het mogelijk maken dat deze openbare content te vinden is
        via deze services. Hoewel we uw content kunnen bewerken en de opmaak
        ervan kunnen wijzigen (door deze bijvoorbeeld te vertalen of te
        herschrijven, de grootte, de lay-out of het bestandstype te wijzigen of
        metagegevens te verwijderen), zullen we de betekenis van uw bewoordingen
        niet veranderen; - Omdat u eigenaar van uw content en gegevens bent en
        we slechts over niet-exclusieve rechten hierop beschikken, kunt u ervoor
        kiezen om de content beschikbaar te stellen aan anderen; - U en I For
        You stemmen ermee in dat we door u verstrekte gegevens kunnen openen,
        opslaan, verwerken en gebruiken overeenkomstig de voorwaarden van het
        Privacybeleid en uw keuzes (waaronder instellingen); - Door suggesties
        of andere feedback in verband met onze Services in te dienen via I For
        You, stemt u ermee in dat I For You deze feedback voor enig doeleinde
        kan gebruiken en delen (maar hiertoe niet is verplicht) zonder dat u
        hiervoor wordt gecompenseerd; - U stemt ermee in om alleen content of
        informatie te verstrekken die niet in strijd is met de wet of rechten
        van anderen (met inbegrip van intellectuele eigendomsrechten). Het is uw
        verplichting om uw profielgegevens up-to-date en waarheidsgetrouw te
        houden. I For You kan wettelijk ertoe worden verplicht om bepaalde
        gegevens of content in bepaalde landen te verwijderen. 3.2 Overige
        content en aansprakelijkheid Tijdens het gebruik van de Services, kunt u
        content of gegevens tegenkomen die mogelijk onnauwkeurig, onvolledig,
        vertraagd, misleidend, illegaal, beledigend of anderszins schadelijk
        zijn. Anderen kunnen hun eigen producten en services aanbieden via onze
        Services en we zijn niet verantwoordelijk voor deze activiteiten van
        derden. I For You controleert de content niet die door onze Gebruikers
        of anderen is verstrekt. U stemt ermee in dat we niet aansprakelijk
        kunnen gesteld worden voor de content of informatie van anderen (met
        inbegrip van andere Gebruikers). We kunnen dit misbruik van onze
        Services niet altijd voorkomen en u stemt ermee in dat we niet
        verantwoordelijk zijn voor dit misbruik. U weet dat u of uw organisatie
        mogelijk ten onrechte in verband kan worden gebracht met content over
        anderen wanneer we connecties en volgers laten weten dat u of uw
        organisatie in het nieuws wordt genoemd. 3.3 Algemene beperkingen 3.3.1
        Toegang tot het platform Om een hoge kwaliteit aan te bieden behoudt I
        For You zich het recht voor om de toegang tot het platform te beperken
        en/of geheel of gedeeltelijk te onderbreken voor o.a. de nodige
        onderhoudswerkzaamheden en updates uit te voeren, zelfs zonder
        voorafgaande waarschuwing. I For You doet dit in principe uitsluitend
        als de omstandigheden dit verantwoorden, maar dit is geen absolute
        voorwaarde. 3.3.2 Persoonlijke toegang tot het platform I For You
        behoudt zich tevens het recht voor om uw gebruik van de Services te
        beperken, inclusief het aantal connecties en uw mogelijkheid om contact
        op te nemen met andere Gebruikers. I For You behoudt zich het recht voor
        om uw Account te beperken, op te schorten of te beëindigen indien I For
        You een overtreding vaststelt van deze Gebruiksovereenkomst of u de wet
        overtreedt. 3.4 Intellectuele eigendomsrechten We stellen u op de hoogte
        van onze intellectuele eigendomsrechten, zie verder hierover artikel 11.
        I For You behoudt zich alle intellectuele eigendomsrechten op de
        Services voor. Andere handelsmerken en logo’s in verband met de Services
        zijn handelsmerken van hun respectievelijke eigenaren. 3.5
        Geautomatiseerde verwerking We gebruiken data en gegevens over u om u en
        anderen relevante suggesties te bieden. We gebruiken gegevens en data
        die u verstrekt en die we over onze Gebruikers in bezit hebben, om
        aanbevelingen te doen voor connecties, content en functies die nuttig
        voor u kunnen zijn. We gebruiken bijvoorbeeld data en gegevens om uw
        dromen aan te bevelen. U dient uw profiel nauwkeurig en up-to-date te
        houden, zodanig dat I For You aanbevelingen nauwkeuriger en relevanter
        kan maken. Artikel 4 - Disclaimer en beperking van aansprakelijkheid
        4.1. Platform en dienstverlening 4.1.1 Geen garanties I For You geeft
        geen garantie met betrekking tot de Services en garandeert niet dat de
        Services ononderbroken of foutloos functioneren. Services worden
        geleverd op basis van beschikbaarheid. Voor zover toegestaan door
        toepasselijke wetgeving, doet I For You afstand van alle stilzwijgende
        of wettelijke garanties. 4.1.2. Storingen I For You draagt geen enkele
        aansprakelijkheid die zou voortvloeien uit storingen, onderbrekingen,
        schadelijke elementen of defecten aan ons platform, ongeacht het bestaan
        van overmacht of vreemde oorzaak. 4.2 (Uitsluiting van)
        aansprakelijkheid 4.2.1 Aansprakelijkheid I For You is aansprakelijk
        voor elke haar toerekenbare zware of herhaalde lichte contractuele en/of
        buitencontractuele tekortkoming veroorzaakt bij de verlening van de
        Services uit deze voorwaarden. I For You is aansprakelijk in geval van
        fraude of opzet op voorwaarde dat deze fout door I For You veroorzaakt
        werd en schade heeft berokkent. Deze aansprakelijkheid is beperkt tot de
        directe schade die volgt uit de tekortkomingen. De beperkingen van
        aansprakelijkheid in dit artikel 4 zijn onderdeel van de overeenkomst
        tussen u en I For You en zijn van toepassing op alle
        aansprakelijkheidsvorderingen. 4.2.2 Uitsluiting indirecte schade Voor
        zover wettelijk toegestaan, is I For You niet aansprakelijk voor eender
        welke vorm van indirecte schade ten gevolge van haar tekortkomingen.
        Onder indirecte schade valt, niet-limitatief: winstderving of gemiste
        kansen (zakelijk of persoonlijk), emotionele schade, reputatieschade,
        gegevensverlies of enige gevolgschade. Hiertoe kunnen we niet tot het
        betalen van een schadevergoeding worden verplicht. 4.2.3 Vrijwaring
        Tegen I For You kan als platform geen aansprakelijkheidsvordering
        ingesteld worden voor enige content geplaatst door eender wie die niet
        verbonden is met I For You. We zijn daarnaast ook niet verantwoordelijk
        voor het al dan niet verbinden van dromen. Indien de Gebruiker door een
        hem toerekenbare contractuele of buitencontractuele tekortkoming de
        aansprakelijkheid van I For You in het gedrang brengt en/of schade,
        verlies en kosten (inclusief kosten voor juridische bijstand) in hoofde
        van I For You veroorzaakt, moet Gebruiker alle nodige maatregelen nemen
        om I For You van deze nadelige gevolgen te vrijwaren. I For You kan
        hiervoor schadevergoeding bekomen. I For You neemt tevens redelijke
        maatregelen om het platform te vrijwaren van o.a. beledigende,
        racistische, mensonterende of xenofobische uitspraken en andere
        ongepaste uitingen vermeld onder artikel 8.2. Artikel 5 - Beëindiging U
        en I For You kunnen het contract voortvloeiend uit deze
        Gebruiksovereenkomst op elk gewenst moment beëindigen door de andere
        partij hiervan op de hoogte te stellen. Bij beëindiging verliest u het
        recht om de Services te openen of te gebruiken. Het volgende blijft van
        kracht na beëindiging: - Onze rechten om uw feedback te gebruiken en
        openbaar te maken; - De rechten van Gebruikers en/of Bezoekers om door u
        via de Services gedeelde content en informatie opnieuw te delen; - Het
        verwijderen van het account door Gebruiker brengt ook automatisch het
        verwijderen van de persoonlijke gegevens van Gebruiker met zich mee. Uw
        geanonimiseerde gegevens blijven bewaard op onze servers ter verbetering
        van onze Services, zie hiervoor ons Privacybeleid. Artikel 6 -
        Toepasselijk recht en geschillenbeslechting Deze overeenkomst en de
        uitvoering daarvan wordt exclusief beheerst door Belgisch recht. In
        geval van enig geschil omtrent de uitvoering van deze overeenkomst wordt
        van de partijen verwacht dat deze alles in het werk stellen om een
        minnelijke oplossing te vinden. Indien de zaak niet geregeld kan worden
        via onderhandeling, kan de zaak worden voorgelegd aan een centrum voor
        arbitrage en mediatie of een bevoegde rechtbank. Enkel de rechtbank van
        arrondissement Antwerpen zal de bevoegdheid hebben over enig geschil
        tussen I For You en de Gebruiker aangaande de geldigheid, de
        interpretatie en de uitvoering van deze overeenkomst. Artikel 7 -
        Gedeeltelijke nietigheid Als een bevoegde rechtbank bepaalt dat een deel
        van deze Gebruiksovereenkomst niet kan worden afgedwongen, stemmen u en
        I For You ermee in dat de rechtbank de voorwaarden zodanig dient aan te
        passen zodat dit gedeelte kan worden afgedwongen, waarbij het beoogde
        doel alsnog wordt gerealiseerd. Als de rechtbank dit niet kan doen,
        stemmen u en I For You ermee in om de rechtbank te verzoeken tot
        verwijdering van het niet-afdwingbare gedeelte en de rest van deze
        Gebruiksovereenkomst nog altijd af te dwingen. Deze Gebruiksovereenkomst
        is de enige overeenkomst met ons met betrekking tot de Services en
        vervangt alle eerdere overeenkomsten voor de Services. Indien we niet
        optreden bij een schending van deze overeenkomst, betekent dat niet dat
        I For You afstand doet van zijn rechten om deze overeenkomst af te
        dwingen. U mag deze overeenkomst (of gebruik van de Services)
        uitsluitend met onze toestemming toewijzen of overdragen aan anderen. U
        stemt er echter mee in dat I For You deze Gebruiksovereenkomst zonder uw
        toestemming kan toewijzen aan zijn gelieerde ondernemingen of een partij
        die ons overneemt. Er zijn geen externe begunstigden voor deze
        overeenkomst. U stemt ermee in dat u juridische kennisgevingen alleen
        naar ons kunt sturen via de adressen in artikel 12. Artikel 8 -
        Gedragsregels voor het gebruik van I For You 8.1 Vereiste handelingen
        Gebruik maken van het platform dient in overeenstemming te gebeuren met
        de wetgeving, voorwaarden en de normen en waarden van I For You. U stemt
        er daarom mee in om het volgende te doen, onverminderd de andere
        bepalingen uit deze Gebruiksovereenkomst: - Alle toepasselijke wetten
        naleven, inclusief, maar niet beperkt tot, privacywetten, intellectuele
        eigendomswetten, anti-spamwetten, exportcontrole wetten, fiscale wetten
        en regelgevende vereisten; - De Services te gebruiken als een goede
        huisvader. 8.2 Verboden handelingen U stemt ermee in om volgende
        verboden handelingen niet te stellen: - Een valse identiteit creëren op
        I For You, u voordoen als iemand anders, een profiel maken voor iemand
        anders dan uzelf (een natuurlijke persoon) of het Account van een ander
        gebruiken of proberen te gebruiken zelfs niet met toestemming van deze
        andere persoon; - Software, apparaten, scripts, robots of andere
        middelen of processen (met inbegrip van crawlers, keyloggers, spiders,
        plug-ins en modules voor browsers, of andere technologieën) ontwikkelen,
        ondersteunen of gebruiken om de Services te doorzoeken of anderszins
        profielen en andere gegevens van de Services te kopiëren; -
        Beveiligingsfuncties omzeilen of toegangscontrolesystemen of
        gebruikslimieten van de Service (zoals hoofdletters in zoekopdrachten
        met trefwoorden of profielweergaven) ontwijken of vermijden; Informatie
        die rechtstreeks of via derden (zoals zoekmachines) via de Services is
        verkregen, zonder toestemming van I For You kopiëren, gebruiken,
        openbaar maken of distribueren; - Het is niet toegestaan het I For
        You-platform en haar gegevens te gebruiken voor spamming,
        kettingbrieven, junk mail en/of gelijkaardige varianten; - Iedere vorm
        van inhoud plaatsen die men als ongepast en/of onwettig kan beschouwen.
        Hieronder verstaan we niet-limitatief iedere vorm van inhoud die onder
        meer illegaal, onrechtmatig, schadelijk, misleidend, bedreigend,
        beledigend, overdadig obsceen, kinderpornografisch, aanstootgevend,
        racistisch en/of in het algemeen strijdig met de openbare orde en goede
        zeden is, wordt als ongepast beschouwd. Deze inhoud hoeft niet
        noodzakelijk in strijd zijn met de geldende wetgeving of de rechten van
        derde partijen om als ongepast beschouwd te worden. - Gegevens openbaar
        maken terwijl u hiervoor geen toestemming hebt (zoals vertrouwelijke
        gegevens van anderen); Intellectuele eigendomsrechten van anderen
        schenden, met inbegrip van auteursrechten, patenten, handelsmerken,
        handelsgeheimen of andere eigendomsrechten. Kopieer of distribueer
        bijvoorbeeld geen bijdragen of andere content van anderen zonder hun
        toestemming (behalve via de beschikbare functionaliteit voor delen),
        welke kan worden verleend door deze onder een Creative Commons-licentie
        te plaatsen; - De intellectuele eigendomsrechten of andere rechten van I
        For You schenden, met inbegrip van, maar niet beperkt tot, (i) het
        kopiëren of distribueren van onze video’s of ander materiaal of (ii) het
        kopiëren of distribueren van onze technologie, tenzij deze is
        vrijgegeven onder open source licenties; (iii) het woord ‘I For You’ of
        onze logo’s gebruiken in een bedrijfsnaam, e-mailadres of URL, tenzij
        zoals is beschreven in de Merkrichtlijnen; - Iets plaatsen dat
        softwarevirussen, malware, trojans, worms of andere schadelijke code
        bevat; - De broncode van de Services of gerelateerde technologie die
        geen open source code is, aan reverse-engineering onderwerpen,
        decompileren, disassembleren, decoderen of anderszins proberen te
        achterhalen; - Impliceren of verklaren dat u bent gelieerd aan of wordt
        gesteund door I For You zonder onze nadrukkelijke toestemming; - Het is
        niet toegestaan om andere Gebruikers te stalken en/of overtuigen van
        bijvoorbeeld politieke en/of religieuze overtuigingen; - Het is niet
        toegestaan om op ongeautoriseerde wijze toegang te verkrijgen tot de
        Gebruikersprofielen van andere Gebruikers;  - Het is niet toegestaan om
        tijdens de registratie en/of aanmelding gebruik te maken van een vreemde
        identiteit en/of vreemde gegevens. Hieronder valt eveneens de aanmelding
        via een computer die fungeert als open proxy; - De Services of
        gerelateerde gegevens verhuren, in lease geven, uitlenen, verhandelen,
        (door)verkopen of anderszins te gelde maken, of u hiertoe toegang te
        verschaffen zonder uitdrukkelijke toestemming van I For You; - Onze
        Services zonder toestemming van I For You deeplinken voor een ander
        doeleinde dan het promoten van uw profiel of een Groep op onze Services;
        - Bots of andere geautomatiseerde methoden gebruiken om de Services te
        openen, contacten toe te voegen of te downloaden of berichten te
        verzenden of door te sturen; - De beschikbaarheid, prestaties of
        functionaliteit van de Services nauwlettend in de gaten houden voor
        concurrentiedoeleinden; - Zich bezighouden met ‘framing’, ‘mirroring’ of
        anderszins toepassen of op andere wijze het uiterlijk of de werking van
        de Services nabootsen; - Een overlay toepassen of anderszins de Services
        of de weergave ervan wijzigen (zoals het invoegen van elementen in de
        Services of een advertentie in de Services verwijderen, afdekken of
        verhullen); - De werking van de Services manipuleren of de Services
        onredelijk belasten (bijvoorbeeld door spam, DoS-aanval (Denial of
        Service), virussen, game-algoritmen); Artikel 9 – Verwerking van
        persoonsgegevens I For You en Gebruiker erkennen dat op de verwerking
        van persoonsgegevens de Verordening (EU) 2016/679 (hierna: de “GDPR”)
        van toepassing is. I For You hecht veel belang aan de privacy en
        correcte verwerking van de persoonsgegevens van haar Gebruikers. Meer
        informatie hieromtrent is opgenomen in het Privacybeleid. Artikel 10 -
        Cookiebeleid I For you stelt u op de hoogte van het cookiebeleid. In de
        Cookieverklaring wordt uiteengezet op welke manier I For You omgaat met
        cookies. Artikel 11 - Intellectuele eigendom 11.1 Toepassingsgebied Alle
        rechten van intellectuele eigendom die verband houden met I For You en
        de dienstverlening zijn en blijven eigendom van I For You. Hieronder
        verstaat men o.a de heel ruime categorie van foto’s, video’s, audio,
        tekst, ideeën, notities, tekeningen en artikels. De Gebruiker verplicht
        zich de informatie op geen enkele wijze, in gewijzigde noch in
        ongewijzigde vorm, in exploitatie te nemen of toe te passen (anders dan
        voor het reguliere gebruik van I For You) zonder voorafgaande
        schriftelijke toestemming van I For You. 11.2 Afbeeldingen Indien I For
        You voor een dienst afbeeldingen of enig ander goed waarop een
        intellectueel recht kan rusten, ontvangt van de klant, dan garandeert de
        klant dat de erop van toepassing zijnde intellectuele rechten zijn
        eigendom zijn en dat I For You de toestemming krijgt om de afbeeldingen
        te gebruiken. Indien de intellectuele rechten niet bij de klant berusten
        en I For You door een derde wordt aangesproken wegens een inbreuk op de
        intellectuele rechten, dan zal de Gebruiker en/of diegene die bij deze
        schending betrokken is I For You integraal vrijwaren voor alle
        aanspraken (hoofdsom, intresten en kosten niets uitgezonderd) die tegen
        I For You worden gericht, alsook de kosten voor vertegenwoordiging (die
        vrij te kiezen is door I For You) en de daarbijhorende proceskosten.
        11.3 Derden We respecteren de intellectuele eigendomsrechten van
        anderen. We vereisen dat door Gebruikers geplaatste gegevens volgens
        deze Gebruiksovereenkomst nauwkeurig moet zijn en niet in strijd zijn
        met de intellectuele eigendomsrechten of andere rechten van derden.
        Artikel 12 – Contact Indien u algemene vragen heeft, kunt u online
        contact met ons opnemen. Voor juridische kennisgevingen of betekening
        van dagvaardingen kunt u schrijven naar volgend adres: E-mailadres:
        legal@katz.be Adres: Handelslei 193, 2980 Zoersel
      </div>

      <h3 class="my-3 fg-color1">Cookiebeleid I For You</h3>
      <div>
        1. Wanneer is dit cookiebeleid van toepassing? I For you is van de
        onderneming Katz N.V. te Handelslei 193, 2980 Zoersel. In dit
        Cookiebeleid zetten we uiteen hoe we zullen omgaan met cookies. We
        geloven in duidelijkheid en openheid in de manier waarop gegevens worden
        verzameld en gebruikt. Dit Cookiebeleid is van toepassing op de
        volledige site, alsook mobiele applicaties. We gebruiken cookies om de
        werking van deze applicatie te bevorderen. 2. Wat zijn cookies? Een
        cookie is een klein bestand dat op uw apparaat wordt opgeslagen en
        waarmee de functies van deze applicatie mogelijk worden gemaakt. Bij elk
        bezoek aan onze applicatie, kan je cookies van ons ontvangen. Gebruikers
        kunnen deze cookies beheren in de instellingen, en verwijderen bij het
        verlaten van de applicatie. Het uitschakelen van de cookies in de
        instellingen kan gevolgen hebben voor de optimale werking van de
        applicatie. 3. Hoe gebruiken wij cookies? Cookies worden bewaard nadat
        je op de akkoord/acceptatie button hebt geklikt. Daarnaast is er ook de
        mogelijkheid om dit te weigeren door op de button voor weigering te
        klikken. Via lokale opslag kan deze applicatie of toepassing gegevens
        lokaal opslaan op uw apparaten. Als een gebruiker cookies weigert worden
        enkel de pagina’s en functionaliteiten weergegeven en toegepast die deze
        cookies niet vereisen. Met het oog op transparantie bevat dit beleid
        gedetailleerde informatie over welke cookies we gebruiken: - Essentiële
        cookies: Deze cookies zijn noodzakelijk voor het goed functioneren van
        deze applicatie en kan je bijgevolg niet weigeren als je I For You wil
        gebruiken. - Functionele cookies: Deze cookies vereenvoudigen het bezoek
        aan de applicatie en verbeteren de gebruiksvriendelijkheid ervan door de
        informatie van je keuzes en voorkeuren op de applicatie te onthouden. Je
        kan deze cookies niet weigeren als je I For You wil gebruiken. -
        Analytische cookies: Cookies die informatie verzamelen over het gedrag
        van bezoekers en de prestaties van de applicatie. Aan de hand van de
        resultaten kan de applicatie verbeterd worden en krijgen bezoekers een
        betere gebruikservaring. Aan de hand van deze cookies zijn we niet in
        staat gebruikers te identificeren. Alle door deze cookies verzamelde
        gegevens worden anoniem verwerkt. - Marketing cookies: Deze cookies
        worden niet gebruikt. 4. Waarvoor gebruiken we deze cookies? -
        Verificatie: als je bent aangemeld kunnen we via deze cookies de juiste
        informatie zichtbaar maken en je ervaring aanpassen overeenkomstig je
        instellingen. - Beveiliging: We gebruiken cookies zodat je deze
        applicatie sneller en veiliger kan gebruiken. Hiermee kunnen we ook
        schadelijke activiteiten en schendingen detecteren. - Voorkeuren,
        functies en services: We gebruiken deze cookies om de site te
        functionaliseren. Deze technologieën kunnen ook gebruikt worden om
        gegevens over uw browser en uw voorkeuren te onthouden. - Prestaties,
        statistieken en onderzoek: Met deze cookies kunnen we het gebruik van
        deze applicatie onderzoeken en verbeteren. Op basis hiervan genereren we
        statistieken. 5. Welke derden gebruiken deze cookies in verband met onze
        Services? Derden, zoals onze klanten, partners en dienstverleners,
        kunnen cookies gebruiken in verband met onze Services. Derden kunnen ook
        cookies gebruiken in verband met onze Services buiten de applicatie,
        zoals I For You-advertentieservices. Derden kunnen cookies gebruiken om
        ons te helpen bij de levering van onze Services. We kunnen ook
        samenwerken met derden voor onze eigen marketingdoeleinden en om onze
        Services te analyseren en te onderzoeken. 6. Hoe kan u cookies beheren?
        U kan zelf kiezen hoe I For You cookies mag gebruiken. Als u beperkt
        welke cookies I For You mag gebruiken, kan uw algehele gebruikservaring
        verminderen omdat I For You niet langer op u wordt afgestemd. Ook kan u
        mogelijk geen aangepaste instellingen opslaan, zoals
        aanmeldingsgegevens. In de meeste browsers kan je cookies regelen via de
        instellingen, waarin je je toestemming voor het gebruik van cookies kan
        aanpassen. Bovendien kan je in de meeste browsers cookies bekijken en
        wissen, waaronder de cookies van deze site. Meer informatie over de
        besturingselementen van de browser vind je in de documentatie van je
        browserfabrikant. 7. Hoe kan u op de hoogte blijven van de wijzigingen
        in dit Cookiebeleid? Gelet op mogelijke veranderingen en verbeteringen
        in het wetgevend kader zal deze pagina op tijd en stond aangepast worden
        indien nodig om de wetgever te volgen. Het is daarom aangeraden deze
        pagina op regelmatige wijze te bezoeken. Eventuele aanpassingen en/of
        wijzigingen worden op een duidelijk zichtbare manier aangegeven. 8. Hoe
        kan u ons contacteren? Heeft u verdere vragen over de wijze waarop wij
        uw persoonsgegevens verzamelen, bewaren en gebruiken, of wenst u meer
        informatie over het uitoefenen van uw rechten onder de privacywetgeving,
        gelieve ons Privacybeleid te raadplegen. Voor alle vragen voor ons
        gebruik van cookies, kunt u ons contacteren via: - E-mail: legal@katz.be
        - Post: Handelslei 193, 2980 Zoersel
      </div>
      <h3 class="my-3 fg-color1">Privacybeleid</h3>
      <div>
        1. Introductie Wat is I For You? I For you is het platform/applicatie
        van de onderneming Katz N.V. te Handelslei 193, 2980 Zoersel. In dit
        Privacybeleid stellen we uiteen hoe dit platform zal omspringen met uw
        persoonsgegevens. Uw privacy is belangrijk De missie van I For You is om
        professionals samen te brengen en om professionele relaties op te bouwen
        en te onderhouden. Hierbij willen we de grootst mogelijke transparantie
        aan de dag leggen en daarom zullen we in dit Privacybeleid uiteenzetten
        welke gegevens we van u verzamelen en hoe we deze gebruiken en delen.
        Daarnaast wijzen we u ook op de privacyrechten die u te allen tijden kan
        uitoefenen. Waarop is dit Privacybeleid van toepassing? Dit
        Privacybeleid - en toekomstige updates ervan - is van toepassing op
        IForYou.com en alle andere aan I For You-gerelateerde websites, apps,
        berichten en services. Het is van toepassing op het verzamelen,
        gebruiken en delen van persoonsgegevens van diverse soorten Gebruikers,
        waaronder „Bezoekers”. Wat als dit Privacybeleid gewijzigd wordt? I For
        You kan dit Privacybeleid aanpassen. Als het gaat om wezenlijke
        wijzigingen, stellen we u hiervan op de hoogte, zodat u de kans heeft de
        wijzigingen door te nemen voordat ze in werking treden. Als u niet
        akkoord gaat met de wijziging, kan u uw account verwijderen. Als u de
        services blijft gebruiken na een kennisgeving van de wijzigingen, erkent
        u dat het verzamelen, gebruiken en delen van hun persoonsgegevens onder
        het gewijzigde Privacybeleid vallen vanaf de ingangsdatum. Wie is de
        databeheerder en contractspartij? De verwerkingsverantwoordelijke van de
        persoonsgegevens die worden verwerkt via I For You is Katz N.V. Wat
        wordt er gedeeld en met wie? Gebruikers delen hun identiteit en
        communiceren met hun netwerk, indien ze voor deze optie hebben gekozen.
        Ze wisselen kennis, dromen en inzichten uit en plaatsen en beoordelen
        relevante content. De content van sommige van onze Services kunnen
        zichtbaar zijn voor Bezoekers. 2. Welke persoonsgegevens verzamelen we?
        2.1 De door u aan ons verstrekte gegevens Een eerste categorie
        persoonsgegevens die we verzamelen zijn de persoonsgegevens die u aan
        ons verstrekt. Registratie Om een account te kunnen aanmaken, dient u
        enkele persoonsgegevens te verstrekken. Het gaat onder andere om uw
        naam, e-mailadres of telefoonnummer, leeftijd en een wachtwoord. Profiel
        Eens u een Account hebt aangemaakt, kan u vrij kiezen welke gegevens u
        vrijgeeft via uw profiel. Hoewel er geen verplichting bestaat gegevens
        te verstrekken, kan u meer halen uit onze services mét profielgegevens.
        U kan bijvoorbeeld eenvoudiger gevonden worden wat de kansen op
        connecties aanzienlijk vergroot. U bepaalt zelf of u gevoelige gegevens
        opneemt in uw profiel. Het is evenwel verstandig geen gegevens toe te
        voegen die u niet algemeen bekend wil maken. Plaatsen en uploaden We
        verzamelen uw persoonsgegevens wanneer u deze verstrekt aan, plaatst op
        en uploadt naar onze services. U bent niet verplicht persoonsgegevens te
        plaatsen of te uploaden. Als u er echter voor kiest dit niet te doen,
        zal het mogelijk moeilijker zijn uw netwerk uit te breiden en te
        benutten via onze services. 2.2 Gegevens van anderen Content en nieuws
        Uzelf en anderen kunnen content plaatsen op onze Services waarin
        gegevens over u vervat liggen (als onderdeel van artikelen, bijdragen,
        commentaar en video’s). We kunnen openbare gegevens over u verzamelen en
        deze beschikbaar maken als onderdeel van onze Services. 2.3 Gebruik van
        de Services We registreren gebruiksgegevens wanneer u onze services
        bezoekt of anderszins gebruikt, zoals wanneer u content of advertenties
        bekijkt of erop klikt, wanneer u een zoekopdracht uitvoert, wanneer u
        één van onze mobiele apps installeert of bijwerkt, wanneer u artikels
        deelt of op dromen reageert. We gebruiken hierbij aanmeldingsgegevens,
        cookies, apparaatgegevens en IP-adressen. 2.4 Cookies en soortgelijke
        technologieën Zoals beschreven in ons Cookiebeleid gebruiken we cookies
        en soortgelijke technologieën om gegevens te verzamelen om u en uw
        apparaten te herkennen wanneer u contact heeft gehad met onze Services.
        Daarnaast staan we toe dat sommige derden onze cookies gebruiken. 2.5 Uw
        apparaat en locatie Als u onze Services bezoekt of verlaat, ontvangen we
        de URL van zowel de website waar u vandaan kwam als de website waar u
        naartoe gaat en het moment van uw bezoek. Verder ontvangen we gegevens
        over uw mobiele netwerk en apparaat (bijvoorbeeld IP-adres, proxyserver,
        besturingssysteem, webbrowser en invoegtoepassingen, apparaat-id en
        -functies, cookie-id’s en/of uw mobiele aanbieder). 2.6 Berichten We
        verzamelen gegevens over u als u door middel van onze Services berichten
        verzendt, ontvangt of gebruikt. Als u bijvoorbeeld een connectieverzoek
        ontvangt op I For You, volgen we of u op het verzoek hebt gereageerd en
        sturen we u herinneringen. We gebruiken ook technologie voor
        automatische scans voor berichten om onze applicatie te ondersteunen en
        te beschermen. We gebruiken deze technologie bijvoorbeeld om mogelijke
        reacties op berichten voor te stellen en om content die in strijd is met
        onze Gebruiksovereenkomst te beheren of te blokkeren van onze Services.
        2.7 Sites en services van anderen We ontvangen gegevens over uw bezoeken
        en interactie met Services van anderen wanneer u zich aanmeldt bij I For
        You of Services van derden die sommige van onze plug-ins, advertenties,
        cookies of soortgelijke technologieën bevatten. 2.8 Overig Als we
        wezenlijk andere persoonsgegevens verzamelen of wezenlijk wijzigen hoe
        we deze gegevens verzamelen, gebruiken of delen, stellen we u hiervan op
        de hoogte en kan ook dit Privacybeleid worden aangepast. 3. Hoe
        gebruiken we deze persoonsgegevens? Hoe we uw persoonsgegevens
        gebruiken, hangt af van de Services die u gebruikt, hoe u deze Services
        gebruikt en de keuzes die u maakt in uw instellingen. We gebruiken de
        gegevens die we over u hebben om onze Services te leveren en te
        personaliseren, onder andere met behulp van geautomatiseerde systemen en
        afgeleide informatie, zodat onze Services relevanter en nuttiger kunnen
        zijn voor u en anderen. 3.1 Services We gebruiken uw gegevens ter
        verificatie voor toegang tot onze Services en het respecteren van uw
        instellingen. Contact We gebruiken uw gegevens (zoals uw profiel of
        andere profielen die u hebt bekeken) om anderen uw profiel te helpen
        vinden, én u en anderen mogelijke dromen voor te stellen. We gebruiken
        ook uw locatie of nabijheid tot anderen voor bepaalde taken
        (bijvoorbeeld om andere Gebruikers bij u in de buurt voor te stellen met
        wie u een connectie kunt maken, …). Blijf op de hoogte Met onze Services
        kan u op de hoogte blijven van nieuwsberichten, evenementen en ideeën
        over interessante onderwerpen uit waar u van droomt, afkomstig van
        mensen met dezelfde of gelijkaardige dromen. We gebruiken de gegevens
        die we over u hebben (bijvoorbeeld gegevens die u verstrekt, gegevens
        die we verzamelen tijdens uw interactie met onze Services en informatie
        die we afleiden uit de gegevens waarover we beschikken) om onze Services
        voor u te personaliseren. We gebruiken ook de gegevens die we over u
        hebben, om suggesties te doen voor elementen die u kunt toevoegen aan uw
        profiel en vaardigheden die u mogelijk nodig hebt voor uw volgende
        droom. Als u ons dus laat weten dat u geïnteresseerd bent in een nieuwe
        vaardigheid (bijvoorbeeld door een video te bekijken), gebruiken we deze
        gegevens om content in uw feed te personaliseren, suggesties te doen
        voor het volgen van bepaalde Gebruikers op onze applicatie, of
        gerelateerde content te bekijken waarmee u aan die nieuwe vaardigheid
        kunt werken. We gebruiken uw content, activiteiten en andere gegevens,
        waaronder uw naam en foto, om berichten naar uw netwerk en anderen te
        verzenden. Afhankelijk van uw instellingen kunnen we bijvoorbeeld
        anderen op de hoogte stellen dat u uw profiel hebt bijgewerkt, content
        hebt geplaatst, een sociale actie hebt uitgevoerd, een functie hebt
        gebruikt, nieuwe connecties hebt gemaakt of in het nieuws bent genoemd.
        Carrière Met onze Services kan u uw dromen een boost geven: u kan
        mogelijkheden zoeken om deze te realiseren. We gebruiken uw gegevens om
        dromen aan te bevelen en om relevante connecties te tonen aan u en
        anderen. U kan aangeven dat u geïnteresseerd bent in een droom en
        gegevens delen met andere Gebruikers. We gebruiken uw gegevens om u en
        anderen dromen aan te bevelen. We kunnen geautomatiseerde systemen
        gebruiken om content en aanbevelingen aan te bieden en op Gebruikers af
        te stemmen, zodat onze Services relevanter worden voor andere Gebruikers
        en Bezoekers. U houdt uw profiel accuraat en up-to-date , waardoor u
        beter onze Services kunt benutten. Productiviteit Indien u ervoor
        gekozen hebt, kan u via onze Services met andere Gebruikers
        communiceren. Afhankelijk van uw instellingen scannen we berichten om
        ‘bots’ of soortgelijke tools te kunnen bieden waarmee taken worden
        vereenvoudigd, zoals het opstellen van reacties, het samenvatten van
        berichten of het aanbevelen van volgende stappen. 3.2. Communicatie We
        kunnen u berichten sturen over de beschikbaarheid van onze Services,
        beveiliging of andere servicegerelateerde kwesties. We kunnen u ook
        berichten sturen over het gebruik van onze Services, netwerkupdates,
        herinneringen, suggesties voor dromen, en reclameboodschappen van ons en
        onze partners. U kan uw communicatievoorkeuren op elk gewenst moment
        wijzigen. Hou er rekening mee dat u zich niet kunt afmelden voor de
        ontvangst van onze serviceberichten, waaronder beveiliging en juridische
        kennisgevingen. 3.3. Reclame Advertenties zijn gericht op Gebruikers,
        Bezoekers en anderen en gebruiken hierbij de volgende gegevens,
        afzonderlijk of in combinatie: Gegevens van reclametechnologieën binnen
        en buiten onze Services, pixels, advertentietags, cookies en
        apparaat-id’s; Door leden verstrekte gegevens; Gegevens over uw gebruik
        van onze Services zoals beschreven in artikel 2.3; Informatie van
        advertentiepartners, leveranciers en uitgevers; en Informatie die van
        bovenstaande gegevens is afgeleid We tonen u advertenties die
        gesponsorde content worden genoemd en eruitzien als niet-gesponsorde
        content, maar toch als advertentie zijn aangemerkt (bijvoorbeeld
        ‘advertentie’ of ‘gesponsord’). Als u iets doet met deze advertenties
        (door deze bijvoorbeeld te markeren als interessant, te becommentariëren
        of te delen), wordt uw actie gekoppeld aan uw naam en is deze zichtbaar
        voor anderen, waaronder de adverteerder. Afhankelijk van uw
        instellingen, als u een sociale actie uitvoert op de I For You-Services,
        kan deze actie worden genoemd bij gerelateerde advertenties. Gegevens
        voor advertentieproviders We delen uw persoonsgegevens niet met externe
        adverteerders of advertentienetwerken, behalve in het geval van: (i)
        gehashte id’s of apparaatidentificators (voor zover ze in sommige landen
        als persoonsgegevens worden beschouwd); (ii) met uw afzonderlijke
        toestemming (zoals bij formulieren voor het genereren van leads); of
        (iii) gegevens die al zichtbaar zijn voor gebruikers van de Services
        (zoals uw profiel). Als u echter een advertentie binnen of buiten onze
        Services bekijkt of hierop klikt, ontvangt de advertentieprovider een
        signaal dat iemand de pagina met de advertentie heeft bezocht. Via het
        gebruik van mechanismen zoals cookies kunnen ze vaststellen dat u dat
        bent geweest. Advertentiepartners kunnen persoonlijke gegevens die door
        de adverteerder rechtstreeks bij u zijn verzameld, koppelen aan uw
        gehashte id’s of apparaatidentificators die van ons zijn ontvangen. 3.4.
        Marketing Naast het adverteren van onze Services gebruiken we gegevens
        van Gebruikers en content voor uitnodigingen en berichten waarmee
        lidmaatschap en netwerkgroei, interacties en onze Services worden
        gepromoot, bijvoorbeeld uw connecties laten zien dat u een functie op
        onze Services hebt gebruikt. 3.5. Ontwikkeling van Services en onderzoek
        Serviceontwikkeling We gebruiken gegevens, waaronder openbare feedback,
        om via onderzoek en ontwikkeling van onze Services u en anderen een
        betere en persoonlijkere ervaring te kunnen bieden, het aantal
        Gebruikers en de betrokkenheid bij onze Services te kunnen vergroten en
        Gebruikers te kunnen helpen om contact te leggen en opportuniteiten te
        vinden. Overig onderzoek We proberen opportuniteiten te creëren voor al
        onze Gebruikers. We gebruiken de persoonlijke gegevens waarover we
        beschikken om o.a. sociale en droomgerelateerde trends te onderzoeken,
        zoals de beschikbaarheid van dromen en dergelijke. In sommige gevallen
        werken we samen met vertrouwde derden om dit onderzoek te verrichten
        onder controlesystemen die zijn ontworpen om uw privacy te beschermen.
        We publiceren inzichten als samengevoegde gegevens in plaats van
        persoonlijke gegevens of staan anderen toe deze publiceren. 3.6.
        Klantondersteuning We gebruiken gegevens (met inbegrip van uw berichten)
        om klachten en problemen met de Services (zoals bugs) te onderzoeken,
        hierop te reageren en deze op te lossen. 3.7. Inzichten die niet tot u
        kunnen worden herleid We gebruiken uw gegevens om inzichten te bekomen
        en te delen die niet tot u persoonlijk kunnen worden herleid. We kunnen
        bijvoorbeeld uw gegevens gebruiken om bijvoorbeeld statistieken over
        onze Gebruikers en hun dromen te genereren om statistische gegevens te
        bekomen of om demografische gegevens van andere Gebruikers of Bezoekers
        voor een Service of demografische inzichten in de bevolking te
        publiceren. 3.8. Beveiliging en onderzoek We gebruiken uw gegevens (met
        inbegrip van uw berichten) voor veiligheidsdoeleinden of ter voorkoming
        van mogelijke fraude of andere schendingen van onze Gebruiksovereenkomst
        en/of pogingen om onze Gebruikers, Bezoekers of anderen schade te
        berokkenen. 4. Hoe worden deze persoonsgegevens gedeeld? 4.1 Onze
        services Profiel Uw volledige profiel is zichtbaar voor alle soort
        Gebruikers van onze Services. Afhankelijk van uw instellingen kan het
        ook zichtbaar zijn voor anderen binnen of buiten onze Services
        (bijvoorbeeld Bezoekers van onze Services of gebruikers van zoekmachines
        van derden). Gebruikers, Bezoekers en anderen kunnen uw openbaar
        gedeelde content vinden en zien, met inbegrip van uw naam (en foto als u
        deze hebt verstrekt afhankelijk van uw instellingen). Binnen de
        applicatie zijn bijdragen zichtbaar voor andere Gebruikers. Alle
        gegevens die u deelt via de applicatie, zijn zichtbaar voor Gebruikers
        en voor anderen die deze pagina’s bezoeken. Wanneer u een Gebruiker of
        andere groepen volgt, bent u voor anderen en de paginabeheerder
        zichtbaar als volger. We laten het andere Gebruikers weten wanneer u een
        interactie aangaat met hun bericht of droom, overeenkomstig uw
        instellingen (indien van toepassing). Afhankelijk van uw instellingen
        laten we een Gebruiker weten wanneer u zijn/haar profiel bekijkt.
        Wanneer u de content van een ander (waaronder advertenties) markeert als
        interessant, opnieuw deelt of becommentarieert, kunnen anderen deze
        ‘sociale acties’ zien en deze met u associëren (zoals uw naam, profiel
        en foto, indien door u verstrekt). Archivering van communicatie Sommige
        Gebruikers zijn wettelijk of volgens zakelijke voorschriften verplicht
        om hun berichten en activiteiten op sociale media te archiveren. Ze
        gebruiken services van anderen om hen deze archiveringsservices te
        bieden. Daarom biedt I For You de mogelijkheid tot archivering.
        Wettelijke openbaarmaking Het is mogelijk dat we gegevens over u
        openbaar moeten maken wanneer we hiertoe worden verplicht door een wet,
        dagvaarding of andere juridische procedure of als we in goed vertrouwen
        van mening zijn dat openbaarmaking redelijkerwijs noodzakelijk is om (1)
        vermeende of feitelijke onwettelijke activiteiten te onderzoeken, te
        voorkomen en actie hiertegen te ondernemen of om handhavingsinstanties
        van de overheid bij te staan; (2) onze overeenkomsten met u af te
        dwingen, (3) vorderingen of aantijgingen van derden te onderzoeken en
        ons hiertegen te verdedigen, (4) de veiligheid of integriteit van onze
        Service te beschermen (door bijvoorbeeld de gegevens te delen met
        bedrijven die met soortgelijke bedreigingen worden geconfronteerd); of
        (5) de rechten en veiligheid van I For You, onze Gebruikers, medewerkers
        of anderen uit te oefenen en te beschermen. We proberen Gebruikers op de
        hoogte te stellen van wettelijke verzoeken om hun persoonlijke gegevens
        wanneer dit naar ons oordeel gepast is, tenzij dit wettelijk of volgens
        een gerechtelijke uitspraak verboden is of wanneer het verzoek een
        noodgeval is. We kunnen dergelijke verzoeken betwisten wanneer we de
        verzoeken te breed of vaag achten of wanneer de passende bevoegdheid
        ontbreekt, maar we zeggen niet toe elk verzoek te betwisten. 5. Uw
        rechten en plichten Gegevensopslag We bewaren uw persoonlijke gegevens
        voor de duur van het bestaan van uw account of zolang het nodig is om u
        onze Services te kunnen bieden. Het gaat hierbij om de gegevens die door
        u en anderen aan ons verstrekt zijn en gegevens afgeleid uit uw gebruik
        van onze Services. We bewaren bepaalde gegevens in geanonimiseerde vorm.
        Toegang tot en controle over uw persoonsgegevens Wat betreft het
        verzamelen, gebruiken en delen van uw gegevens heeft u verschillende
        keuzemogelijkheden. U kan bepaalde gegevens verwijderen, corrigeren, de
        zichtbaarheid ervan beheren. Daarnaast kan u zich afmelden voor reclame
        en controlesystemen voor communicatie. Het beheer van uw
        persoonsgegevens verloopt via uw instellingen. Meer in het bijzonder kan
        u: Gegevens verwijderen: u kan ons vragen al uw persoonlijke gegevens of
        bepaalde gegevens te verwijderen (bijv. wanneer deze niet meer nodig
        zijn om u Services te bieden). Gegevens wijzigen of corrigeren: u kan
        sommige van uw persoonlijke gegevens wijzigen via uw account. Ook kan u
        ons in bepaalde gevallen (als er iets onjuist is) vragen om uw gegevens
        te wijzigen, bij te werken of te herstellen. Bezwaar maken, of het
        gebruik van gegevens beperken of verbieden: u kan ons vragen om het
        gebruik van alle of bepaalde persoonlijke gegevens te stoppen (zoals
        wanneer we geen juridisch recht hebben om deze te gebruiken) of het
        gebruik te beperken (wanneer uw persoonlijke gegevens onnauwkeurig zijn
        of onrechtmatig worden bewaard). Recht op toegang en/of meenemen van uw
        gegevens: u kan ons vragen om een kopie van uw persoonlijke gegevens en
        een kopie van uw persoonlijke gegevens die machinaal leesbaar is.
        Bezoekers kunnen meer informatie lezen over hoe dergelijke verzoeken
        kunnen worden ingediend. U kunt ook contact met ons opnemen via de
        contactgegevens hieronder, zodat we uw verzoek kunnen overwegen in
        overeenstemming met de van toepassing zijnde wetgeving. Inwoners van de
        aangewezen landen en andere regio’s beschikken mogelijk over aanvullende
        rechten onder hun lokale wetgeving. Op I For You is steeds de in België
        geldende wetgeving van toepassing. Verwijdering van uw account Als u
        ervoor kiest om uw I For You-account te verwijderen, zijn uw
        persoonlijke gegevens binnen 24 uur niet meer zichtbaar voor anderen op
        onze Services. We verwijderen gegevens van een gesloten account in het
        algemeen binnen 30 dagen nadat de account is gesloten, behalve in de
        gevallen hieronder beschreven. Na verwijdering van uw account worden de
        gegevens van deze account anoniem bijgehouden. De gegevens zijn niet
        meer verbonden of te verbinden met een persoon. We bewaren uw
        persoonlijke gegevens zelfs nadat u uw account hebt gesloten als dat
        redelijkerwijs nodig is om aan onze wettelijke verplichtingen te voldoen
        (waaronder wethandhavingsverzoeken), voorschriften na te leven,
        geschillen op te lossen, de beveiliging te handhaven, fraude en misbruik
        te voorkomen, onze Gebruiksovereenkomst af te dwingen of uw verzoek tot
        uitschrijving voor verdere berichten van ons uit te voeren. We bewaren
        de geanonimiseerde gegevens nadat uw account is gesloten. Gegevens die u
        met anderen hebt gedeeld via het I For You-platform blijven zichtbaar
        nadat u uw account hebt gesloten of de gegevens hebt verwijderd uit uw
        eigen profiel. We hebben geen controle over gegevens die andere
        Gebruikers buiten onze Services hebben gekopieerd. 6. Overige
        belangrijke informatie Beveiliging We implementeren
        veiligheidsmaatregelen die zijn ontworpen om uw gegevens te beschermen,
        zoals HTTPS. We controleren onze systemen regelmatig op mogelijke
        veiligheidsrisico’s en aanvallen. We kunnen de veiligheid van door u
        verzonden gegevens echter niet garanderen. Er bestaat geen garantie dat
        niemand zich toegang verschaft tot gegevens en deze openbaar maakt,
        wijzigt of vernietigt door een schending van onze fysieke, technische of
        bestuurlijke veiligheidsmaatregelen. Juridische grondslagen We zullen
        persoonlijke gegevens alleen verzamelen en verwerken waar er hiervoor
        juridische grondslagen bestaan. Juridische grondslagen bestaan uit onder
        andere toestemming, … . Waar we vertrouwen op uw toestemming om
        persoonlijke gegevens te verwerken, hebt u het recht om uw toestemming
        op elk gewenst moment in te trekken of te weigeren. Contact Als u
        verdere vragen of klachten heeft over dit Privacybeleid, kunt u ons
        contacteren via: - E-mail: legal@katz.be - Post: Handelslei 193, 2980
        Zoersel
      </div>
    </div>
  </div>
</div>
