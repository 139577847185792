<div>
  <!-- <app-navbar></app-navbar> -->
</div>

<div class="test-phone">
  <app-phone>
    <img src="https://picsum.photos/200/300" alt="WeDream rules" class="w-100 h-100 img-cover" loading="lazy" >
  </app-phone>
</div>
<div>
  <app-image-tile [mediaFade]="test"></app-image-tile>
</div>
<div>
  <app-video-tile></app-video-tile>
</div>
<div class="p-5">
  <app-vertical-tile [type]="'social'"></app-vertical-tile>
</div>

<div class="p-5">
  <app-video-square></app-video-square>
</div>

<div>
  <button (click)="callFade()">test nav</button>
</div>  

<div>
  <!-- <app-side-navigation></app-side-navigation> -->
</div>

<app-filler></app-filler>