import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivacyRoutingModule } from './privacy-routing.module';
import { PrivacyComponent } from './privacy.component';
import { TacPageComponent } from './tac-page/tac-page.component';
import { GdprPageComponent } from './gdpr-page/gdpr-page.component';


@NgModule({
  declarations: [
    PrivacyComponent,
    TacPageComponent,
    GdprPageComponent
  ],
  imports: [
    CommonModule,
    PrivacyRoutingModule
  ]
})
export class PrivacyModule { }
