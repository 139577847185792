import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GdprPageComponent } from './gdpr-page/gdpr-page.component';
import { PrivacyComponent } from './privacy.component';
import { TacPageComponent } from './tac-page/tac-page.component';

const routes: Routes = [
  { path: 'terms&conditions', component: TacPageComponent },
  { path: 'gdpr', component: GdprPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivacyRoutingModule {}
