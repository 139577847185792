import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error403Component } from './errorPages/error403/error403.component';
import { Error404Component } from './errorPages/error404/error404.component';
import { Error500Component } from './errorPages/error500/error500.component';
import { SiteRootComponent } from './site-root/site-root.component';

const routes: Routes = [
  { path: '', component: SiteRootComponent },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    //Wild Card Route for 404 request
    path: '404',
    pathMatch: 'full',
    component: Error404Component,
  },
  {
    //Wild Card Route for 404 request
    path: '500',
    pathMatch: 'full',
    component: Error500Component,
  },
  {
    //Wild Card Route for 404 request
    path: '403',
    pathMatch: 'full',
    component: Error403Component,
  },
  {
    //Wild Card Route for 404 request
    path: '**',
    pathMatch: 'full',
    component: Error404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
