import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/eventService';
import { MediaService } from 'src/app/core/services/mediaService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-video-tiles-phases',
  templateUrl: './video-tiles-phases.component.html',
  styleUrls: ['./video-tiles-phases.component.scss'],
})
export class VideoTilesPhasesComponent implements OnInit {
  public mediaList: MediaObject[] = [];
  public media = new MediaObject(0, '', '', '', '', '', '', '', '', '', '');

  constructor(
    private eventService: EventService,
    private mediaService: MediaService
  ) {}

  public setMediaPhone(media: MediaObject): void {
    this.media = media;
    this.eventService.callPhasesPhoneEvent(media);
  }

  private initMedia(): void {
    this.mediaList = this.mediaService.getMediaPhasesPage();
    this.setMediaPhone(this.mediaList[0]);
  }
  ngOnInit(): void {
    this.initMedia();
  }
}
