<div
  class="d-flex flex-wrap align-items-center navi-bar"
  id="nav"
  [ngClass]="{ 'bgi-color2': fade }"
>
  <div class="fade-prep" [ngClass]="{ 'fade-in': fade }">
    <div class="logo-weDream">
      <app-logo></app-logo>
    </div>
  </div>
</div>
