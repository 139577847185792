<div class="touch-body">
  <div
    class="d-flex justify-content-center align-items-center touch-body-content"
  >
    <div class="content-block pr-10">
      <div class="position-relative">
        <h1 class="position-absolute fg-accent1 text-upper touch-title">
          Get in touch
        </h1>
      </div>
      <div class="mb-4">
        We have a dream, and if you’re on this page, we think you do too.
        Download WeDream and start sharing today. Got feedback, a question or a
        genius idea to make WeDream even better? Awesome. That's exactly what we
        need at this stage of our project. And let's be honest: we'll need it
        afterwards too. Without you there is no WeDream!
      </div>
      <div>
        <!-- <app-get-in-touch-form></app-get-in-touch-form> -->
      </div>
    </div>
    <div class="content-block d-flex">
      <div *ngFor="let item of media; let i = index" class="banner">
        <app-vertical-tile
          [type]="'social'"
          [media]="item"
          [title]="i == 0 ? 'social' : ''"
        ></app-vertical-tile>
      </div>
    </div>
  </div>
</div>
