import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteRoutingModule } from './site-routing.module';
import { SiteComponent } from './site.component';
import { PhasesModule } from '../phases/phases.module';
import { TesterModule } from '../tester/tester.module';
import { VisionModule } from '../vision/vision.module';
import { HomePageModule } from '../home-page/home-page.module';
import { TestimonialsModule } from '../testimonials/testimonials.module';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { GetInTouchModule } from '../get-in-touch/get-in-touch.module';


@NgModule({
  declarations: [
    SiteComponent
  ],
  imports: [
    CommonModule,
    SiteRoutingModule,
    HomePageModule,
    TesterModule,
    VisionModule,
    PhasesModule,
    TestimonialsModule,
    CoreModule,
    SharedModule,
    GetInTouchModule

  ],
  exports: [
    SiteComponent
  ]
})
export class SiteModule { }
