<div class="spacer d-flex flex-wrap justify-content-center align-items-center">
  <div class="d-flex flex-wrap justify-content-center text-upper">
    <div class="w-100 text-center fw-500">
      {{fillerContent.preTitle1}} <span class="fg-accent1">{{fillerContent.highlight1}}</span> {{fillerContent.afterTitle1}}
    </div>
    <div class="spacer-line bg-color1 w-100 my-3"></div>
    <div class="w-100 text-center fw-500">
      {{fillerContent.preTitle2}} <span class="fg-accent1">{{fillerContent.highlight2}}</span> {{fillerContent.afterTitle2}}
    </div>
  </div>
</div>