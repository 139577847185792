<div class="phone-testimonial testi-phone">
  <!-- phone container -->
  <app-phone>
    <ng-template [ngIf]="mediaSet" [ngIfElse]="noMedia">
      <div class="w-100 h-100 d-flex justify-content-center">
        <div class="mx-3 pt-6 pb-2 content-phone">
          <!-- titles -->
          <div class="">
            <h3 class="text-upper vision-phone-sub-title text-block">Vision</h3>
            <h1 class="vision-phone-title text-block">{{ media.name }}</h1>
          </div>

          <div class="vision-img my-3">
            <img [src]="media.frameUrl" alt="WeDream rules" loading="lazy" />
          </div>
          <div class="text-block">
            {{ media.description }}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #noMedia>
      <div
        class="d-flex flex-wrap justify-content-center align-items-center h-100"
      >
        <div class="d-flex justify-content-center flex-wrap">
          <!-- pop animation -->
          <div>
            <div class="for fg-color1 mt-4">Make</div>
            <div class="you fg-accent1">Your</div>
            <div class="for">Dreams</div>
            <div class="you">Happen</div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-phone>
</div>
