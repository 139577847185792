<div class="video-container bg-accent1 position-relative">
<img [src]="homeVideo.gifUrl" alt="WeDream rules" loading="lazy">


  <div class="mask1 mask">

  </div>
  <div class="mask2 mask">

  </div>
  <div class="mask3 mask">

  </div>

  <span class="material-icons position-absolute play" (click)="triggerAnimation()">
    play_circle_filled
  </span>
</div>