import { Component, Input, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/eventService';
import { MediaObject } from 'src/app/models/mediaObject';

@Component({
  selector: 'app-circles-navigation',
  templateUrl: './circles-navigation.component.html',
  styleUrls: ['./circles-navigation.component.scss']
})
export class CirclesNavigationComponent implements OnInit {
  @Input() media: MediaObject[] = [];
  @Input() page: string = 'videos';

  constructor(private event: EventService) { }

  public navigate(index: number): void {
    this.determineEvent(this.media[index]);
  }

  private determineEvent(media: MediaObject): void {

    switch (this.page) {
      case 'videos':
        this.event.callVideosPhoneEvent(media);
        return;
      case 'testimonials':
        this.event.callTestimonialPhoneEvent(media);
        return;
      case 'vision':
        this.event.callVisionPhoneEvent(media);
        return;
      case 'phases':
        this.event.callPhasesPhoneEvent(media);
    }
  }

  ngOnInit(): void {
  }

}
