<div class="home-body" id="home">
  <div
    class="d-flex justify-content-center align-items-center phone-container positin-relative"
  >
    <div
      class="fade-block video-block"
      [ngClass]="{
        'video-block-animation': animationTriggerd,
        'video-block-animation-reversed': animationReversed,
        events: !isLoading
      }"
    >
      <h1 class="fg-accent1 home-title block-size">
        <span class="align-iforyou"
          ><span>We</span><span class="fg-color1">Dream</span>
        </span>
      </h1>
      <app-video-home (animationTrigger)="animationTrigger()"></app-video-home>
      <div class="block-size d-flex flex-wrap align-items-center">
        <a
          href="/#tester"
          class="btn btn-lg fg-color2 bg-accent1 text-center"
          id="signUp"
          (click)="testerNavigation()"
          aria-label="Download"
          >Download now</a
        >
      </div>
    </div>

    <div
      [ngClass]="{
        'phone-block': animationTriggerd,
        'phone-block-reversed': animationReversed
      }"
      class="d-flex justify-content-start align-items-start"
    >
      <ng-template [ngIf]="animationTriggerd">
        <div class="video-title fg-accent1">
          {{ media.name }}
        </div>
      </ng-template>

      <div class="phone-block-inner">
        <div id="phone" #phone>
          <app-phone-home
            [animationDown]="animationTriggerd"
            [animationUp]="animationReversed"
          ></app-phone-home>
        </div>
      </div>

      <ng-template [ngIf]="animationTriggerd">
        <div class="video-description">
          {{ media.description }}
        </div>
      </ng-template>
    </div>

    <div
      class="fade-block small-screen-down"
      [ngClass]="{ events: !isLoading }"
    >
      <app-video-tiles [showFade]="animationTriggerd"></app-video-tiles>
    </div>
  </div>
</div>
